/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { addPromoPizza, submitPayment } from '../../../redux/actions/cartActions'; 
//MUI
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormDialog = ({ promo: { firstOrder }, data: { orderId }, cart: { order, products, promoAdded }, addPromoPizza, submitPayment }) => {
  const { product: { options }, product, opened } = firstOrder;
  const [sauce, setSauce] = useState({});
  let sauceOptions = options ? options.filter(option => option.name === 'Соусы').map(sauce => (
    <FormControlLabel
      onClick={() => handleChange(sauce)}
      key={sauce.id}
      value={sauce.value}
      control={<Radio />}
      label={sauce.value} />
  )) : null;
  let pizzaOption = options ? options.filter(option => option.name === 'Традиционное')[0] : null;
  const handleChange = (sauce) => {
    setSauce({
      value: sauce.value,
      name: sauce.name,
      product_option_id: sauce.id
    })
  }
  const handleSubmit = e => {
    e.preventDefault();
    const finalItem = {
      ...product,
      product_id: product.id,
      quantity: 1,
      options: {
        pizza: {
          value: pizzaOption.value,
          name: pizzaOption.name,
          product_option_id: pizzaOption.id
        },
        sauce,
      },
      render_id: new Date().toJSON().slice(0, 10)
    }
    finalItem.total = finalItem.price * finalItem.quantity;
    addPromoPizza(finalItem); 
  }
  
  return (
    <div>
      <Dialog open={opened} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Добавление пиццы {product.name || ''}</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">Выберите соус</FormLabel>
              <RadioGroup name="sauce">
                {sauceOptions}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button type="submit">
              Добавить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => ({
  promo: state.promo,
  data: state.data,
  cart: state.cart
})
export default connect(mapStateToProps, { addPromoPizza, submitPayment })(FormDialog)