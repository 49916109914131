import React, { useEffect } from 'react';
import axios from 'axios';
import OrderItem from '../OrderItem';
import Echo from "laravel-echo";
import { echoOptions } from '../../../util/broadcastOptions';
import {setReadyOrders} from '../../../redux/actions/dataActions';
import {connect} from 'react-redux';
//MUI
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
const styles = (theme) => ({
  ...theme.mainStyles,
  completed: {
    width: '60%',
    overflow: 'auto',
    height: '500px',
  },
  finishedTitle: {
    background: theme.palette.colors.active,
  }
})
const Completed = (props) => {
  const {classes, data: {tvOrders}, setReadyOrders} = props;
  const { store_id } = localStorage
  useEffect(() => { 
    window.Echo = new Echo(echoOptions);
    window.Echo.channel(`product_${store_id}`).listen('.completed', (e) => {
      setReadyOrders(e.products.original);
    }); 
    axios({
      url: '/orders/completed',
      method: 'get',
    })
      .then(res => {
        setReadyOrders(res.data);
      })
      .catch(err => console.log(err));
  }, [])
  const orderContent = !tvOrders.ready ? <p>Загрузка</p> : tvOrders.ready.map(item => <OrderItem ready={true} data={item} key={item.id}/>)
  return (
    <Grid className={classes.completed}>
      <Grid className={`${classes.titleBlock} ${classes.finishedTitle}`}>
        <h1>Готовы</h1>
      </Grid>
      {orderContent}
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  data: state.data
})
export default connect(mapStateToProps, {setReadyOrders})(withStyles(styles)(Completed));
