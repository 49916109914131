import React, { useState } from 'react';
import WriteOffWindow      from '../WriteOffWindow';
import ConfirmAction       from 'components/ConfirmAction';
import { useTime } from 'hooks/timeHook';

//MUI 
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import withStyles from '@material-ui/core/styles/withStyles';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
//icons
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteSweep';
import IconButton from '@material-ui/core/IconButton';
const styles = theme => ({
  ...theme.tables,
  resultContainer: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    width: '130px'
  }
})

const WriteOffTableIngredient = ({ classes, list, getData }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [item, setItem] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { formatDate } = useTime();

  const handleOpenAdd = (data) => {
    setOpenAdd(true);
  }

  const handleCloseAdd = () => {
    setOpenAdd(false);
  }

  const handleOpenDelete = (a) => {
    setItem(a)
    setOpenDelete(true);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  const deleteMethods = {
    method: 'DELETE',
    open: openDelete,
    handleClose: handleCloseDelete,
    question: `Вы действительно хотите удалить ${item.name} из списания?`,
    updateList: getData,
    url: `/admin/analytics/products/writeoffingredient/${item.id}`
  }

  return (
    <Card className={classes.resultContainer}>
      <WriteOffWindow getData={getData} open={openAdd} handleClose={handleCloseAdd} />
      <ConfirmAction config={deleteMethods} />
      <CardContent>
        <Toolbar className={classes.tableTools}>
          <Button onClick={handleOpenAdd} color="primary" variant="contained">
            <AddIcon />
          </Button>
        </Toolbar>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Наименование</TableCell>
              <TableCell className={classes.tableHeader} align="right">Дата</TableCell>
              <TableCell className={classes.tableHeader} align="right">Вес (г.)</TableCell>
              <TableCell className={classes.tableHeader} align="right">Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).reverse().map((order) => {
              const { name, created_at, id, weight } = order;
              return (
                <TableRow hover key={id}>
                  <TableCell component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell align="right">{formatDate(created_at, 'DD.MM.YYYY')}</TableCell>
                  <TableCell align="right">{weight}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleOpenDelete(order)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
          labelRowsPerPage="Элементов на странице"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(WriteOffTableIngredient)