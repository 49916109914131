import * as Yup from 'yup';

export default function UserFormSchema(bool) {
  return Yup.object().shape({
    //квартира
    // flat: Yup.string().when('shipping_method', {
    //   is: 'delivery',
    //   then: Yup.string().required('Введите квартиру'),
    // }),
    //подъезд
    // porch: Yup.string().when('shipping_method', {
    //   is: 'delivery',
    //   then: Yup.string().required('Введите подъезд'),
    // }),
    //домофон
    // porch_code: Yup.string().when('shipping_method', {
    //   is: 'delivery',
    //   then: Yup.string().required('Введите домофон'),
    // }),
    //этаж
    // floor: Yup.string().when('shipping_method', {
    //   is: 'delivery',
    //   then: Yup.string().required('Введите этаж'),
    // }), 
    //номер телефона
    // telephone: Yup.string().required('Введите телефон'),
    //способ оплаты
    pay_method: Yup.string().required('Выберите способ оплаты'),
    //способ доставки  
  });
}
