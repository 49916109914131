/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import ComboOption from '../ComboOption';

const styles = theme => ({
  comboCategory: {
    padding: '15px',
    marginBottom: '20px',
    width: '100%',
    background: 'white',
    transition: '0.3s',
    boxShadow: ` 0px 2px 8px #c9c9c9, 
    -5px -5px 8px #ffffff;`,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: 'white'
    }
  },
  content: {
    position: 'absolute',
    top: 0,
    left: '31%',
    borderRadius: '6px',
    width: '69%',
    display: 'flex',
    flexWrap: 'wrap',
    background: 'white',
    padding: theme.spacing(1),
  },
  activeCategory: {
    background: theme.palette.secondary.main,
    color: 'white'
  },
  disabledContent: {
    display: 'none'
  }
})
const ComboCategory = ({ editCombo, chosenProducts, setChosenProducts, activeCategory, setActiveCategory, classes, category, category: { product_id, id }, products }) => {
  const [chosenItem, setChosenItem] = useState({});
  const makeCategoryActive = () => {
    setActiveCategory(category);
  }

  useEffect(() => {
    let defaultItem = {};
    if (editCombo.products) {
      defaultItem = editCombo.products.find(item => item.option_id === id);
    } else {
      defaultItem = products.find(item => item.product_id === product_id);
    }
    setChosenItem(defaultItem);
  }, [])

  useEffect(() => {
    let newProducts = chosenProducts.filter(item => item.option_id !== id);
    let item = {
      option_id: id,
      ...chosenItem,
    }
    setChosenProducts([
      ...newProducts,
      item
    ])
  }, [chosenItem])
  const isActive = product_id === activeCategory.product_id;

  return (
    <>
      <Button onClick={makeCategoryActive} className={`${isActive ? classes.activeCategory : ''} ${classes.comboCategory}`}>
        {chosenItem.name ? chosenItem.name : ''}
      </Button>
      <div className={`${classes.content} ${!isActive ? classes.disabledContent : ''}`}>
        {products.map((item, i) =>
          <ComboOption chosenProducts={chosenProducts} setChosenProducts={setChosenProducts} categoryId={id} isActive={isActive} chosenItem={chosenItem} setChosenItem={setChosenItem} key={i} option={item} />)}
      </div>
    </>
  )
}

export default withStyles(styles)(ComboCategory)
