import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({

})

const OptionsWindow = ({ setAddedList, item, handleClose, open }) => {

  const addPizza = (option) => {
    let correctOption = {
      product_option_id: option.id,
      name: option.name,
      value: option.value
    }

    let targetProduct = {
      name: item.name,
      product_id: item.id,
      quantity: 1,
      price: 0,
      options: [correctOption],
      is_with_packaging: false
    }

    setAddedList(prev => {
      return [
        ...prev,
        targetProduct
      ]
    })
    handleClose();
  }

  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {
            item && item.options ? item.options.filter((option) => option.name !== 'Соусы').map((option) => {
              return <Button key={option.id} style={{ marginBottom: '1rem' }} variant="outlined" color="primary" onClick={() => addPizza(option)}>{`${option.name} ${option.value}`}</Button>
            }) : null
          }
        </Grid>

      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(OptionsWindow);