import React, { useEffect } from 'react';
import InputMask from 'react-input-mask'
import { useFormik } from 'formik';
import validationSchema from './schema';
import { useHttp } from 'hooks/httpHook';
//MUI
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
const BlockPhoneWindow = ({ openAdd, setOpenAdd, getBlackList }) => {
  const { request, loading, success, error, clearError } = useHttp();

  const formik = useFormik({
    initialValues: {
      telephone: "",
      reason: "",
    },
    validationSchema,
    onSubmit: async values => {
      values.telephone = values.telephone.replace(/[^+0-9]/gim, '');
      await request('/blacklist', 'post', values);
    }
  })

  useEffect(() => {
    if (success) {
      getBlackList();
    }
  }, [success])
  return (
    <Dialog open={openAdd} onClose={() => setOpenAdd(false)} aria-labelledby="form-dialog-title">
      <DialogTitle>Добавить номер в черный список</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <InputMask
            name="telephone"
            mask="+7 (999) 999-99-99"
            defaultValue={formik.values.telephone}
            onChange={formik.handleChange}
            error={formik.touched.telephone && formik.errors.telephone ? true : false}
            helperText={formik.touched.telephone && formik.errors.telephone ? formik.errors.telephone : null}
            onBlur={formik.handleBlur}
          >
            <TextField
              fullWidth
              label="Введите номер телефона"
            />
          </InputMask>
          <TextField
            label="Введите причину"
            name="reason"
            defaultValue={formik.values.reason}
            onChange={formik.handleChange}
            type="text"
            fullWidth
            error={formik.touched.reason && formik.errors.reason ? true : false}
            helperText={formik.touched.reason && formik.errors.reason ? formik.errors.reason : null}
            onBlur={formik.handleBlur}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAdd(false)} color="primary">
            Закрыть
          </Button>
          <Button disabled={loading} variant="contained" type="submit" color="primary">
            Добавить
          </Button>
        </DialogActions>
      </form>
      <Snackbar
        open={!!error}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity="error">Произошла ошибка, попробуйте позже</Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          variant="filled"
          severity="success">Номер успешно изменен</Alert>
      </Snackbar>
    </Dialog>
  )
}

export default BlockPhoneWindow
