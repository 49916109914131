import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { addProductToCart, checkComboCombination, openProductInfo, closeProductInfo } from '../../../../redux/actions/cartActions';
//MUI
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  productItemWrap: {
    position: 'relative',
    fontSize: '16px',
    minHeight: '70px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px',
    transition: '0.3s'
  },
  productItemBlock: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  productItemActive: {
    background: theme.palette.secondary.main,
    color: 'white'
  }
})
const CategoryItem = ({ product, cart, classes, addProductToCart, openProductInfo, checkComboCombination }) => {

  let mouseTimer;
  const mouseDown = (e) => {
    mouseUp();
    mouseTimer = window.setTimeout(execMouseDown, 300);
  }
  const mouseUp = () => {
    if (mouseTimer) window.clearTimeout(mouseTimer);
  }
  const execMouseDown = () => {
    const itemInfo = {
      title: product.name,
      description: product.description
    }
    openProductInfo(itemInfo);
  }
  return (
    <Card
      className={product.id === (cart.editProduct.oldId || cart.editProduct.id) ? `${classes.productItemWrap} ${classes.productItemActive}` : classes.productItemWrap}
      onTouchCancel={() => mouseUp()}
      onMouseUp={() => mouseUp()}
      onTouchStart={(e) => mouseDown(e)}
      onMouseDown={(e) => mouseDown(e)}
      onClick={() => { addProductToCart(product); checkComboCombination()}}>
      <p>{product.name}</p>
      <Grid className={classes.productItemBlock}></Grid>
    </Card>
  )
}
const mapStateToProps = (state) => ({
  data: state.data,
  cart: state.cart
})
export default connect(mapStateToProps, { checkComboCombination, addProductToCart, openProductInfo, closeProductInfo })(withStyles(styles)(CategoryItem));
