import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
//MUI
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  categoryTitle: {
    fontSize: '25px',
    padding: '5px 0 5px 5px',
    width: '200px',
    margin: '10px 0 10px 0.5%',
    height: '30px',
    background: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  categoryWrap: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridGap: theme.spacing(1),
  },
  productItemWrap: {
    position: 'relative',
    minHeight: '70px',
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px',
    background: '#ffffff'
  },
})
const MenuSkeleton = (props) => {
  const { classes } = props;
  const blocks = Array.from({ length: 15 }).map((item, index) => (
    <Card key={index} className={classes.productItemWrap} />
  ))
  const content = Array.from({ length: 5 }).map((item, index) => (
    <Grid key={index}>
      <Typography variant="h1" className={classes.categoryTitle} />
      <Grid className={classes.categoryWrap}>
        {blocks}
      </Grid>
    </Grid>
  ));
  return content;
}

export default withStyles(styles)(MenuSkeleton)
