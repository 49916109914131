import React, { useState } from 'react'
//MUI 
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const CartComment = (props) => {
  const { product, openComment, setOpenComment, addComment, defaultValue } = props;
  const [comment, setComment] = useState('');
  const submitComment = () => {
    if (product && product.name) {
      addComment(product.render_id, comment)
    } else {
      addComment(comment);
    }
    setOpenComment(false);
  }
  return (
    <Dialog open={openComment} onClose={() => setOpenComment(false)} fullWidth maxWidth="sm">
      <DialogTitle>Комментарий к пицце</DialogTitle>
      <DialogContent>
        <TextField defaultValue={defaultValue} onChange={(e) => setComment(e.target.value)} fullWidth label="Оставьте комментарий" variant="outlined" />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenComment(false)} color="primary">
          Закрыть
          </Button>
        <Button onClick={submitComment} color="primary">
          Добавить
          </Button>
      </DialogActions>
    </Dialog>
  )
}
export default CartComment;
