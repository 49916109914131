import React from 'react' 
import { Redirect } from 'react-router-dom'; 
const SwitchPosition = () => {
  const token = localStorage.FBIdToken;
  const profPath = `/${localStorage.path}`;
  return (
      token === undefined ? <Redirect to="/login"/> : <Redirect to={profPath}/>
  )
}

export default SwitchPosition;
