/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ComboCategory from './ComboCategory';
import { connect } from 'react-redux';
import { addComboToCart, updateComboInCart } from '../../../redux/actions/cartActions';
//MUI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';

const styles = theme => ({
  comboDialog: {
    position: 'relative',
    padding: theme.spacing(2)
  },
  comboTitle: {
    paddingBottom: '15px',
  },
  comboWrap: {
    height: '500px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  categoriesWrap: {
    background: theme.palette.colors.grey,
    padding: theme.spacing(1),
    width: '30%',
  },
  categories: {
    height: '350px',
    overflow: 'auto',
  },
  optionsWrap: {
    width: '69%',
    padding: theme.spacing(1),
  },
  addPizza: {
    marginTop: '1rem',
    width: '100%',
  }
})
const MakeCombo = ({ cart: { comboWindowOpened }, addComboToCart, classes, closeMakeCombo, editCombo, updateComboInCart }) => {
  const [activeCategory, setActiveCategory] = useState({});
  const [chosenProducts, setChosenProducts] = useState([]);
  const { name, options, initial_products, total } = editCombo;
  let categories = comboWindowOpened ? options.map((category) => {
    let categoryProducts = initial_products.filter(item => item.category_id === category.category_id);
    return (
      <ComboCategory
        key={category.product_id}
        setChosenProducts={setChosenProducts}
        chosenProducts={chosenProducts}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        editCombo={editCombo}
        category={category}
        products={categoryProducts} />
    )
  }) : [];
  const sendInfo = () => {
    let finalItem = {
      ...editCombo,
      products: chosenProducts
    }
    if (editCombo.products) {
      updateComboInCart(finalItem);
      setActiveCategory({});
    } else {
      addComboToCart(finalItem);
      setActiveCategory({});
    }
  }

  useEffect(() => {
    if (editCombo.products) {
      setChosenProducts(editCombo.products);
    } else {
      let chosenItems = []
      if (comboWindowOpened) {
        options.forEach(option => {
          initial_products.forEach(product => {
            if (option.product_id === product.product_id) {
              chosenItems.push({
                ...product,
                option_id: option.id
              });
            }
          })
        })
      }
      setChosenProducts(chosenItems);
    }

  }, [comboWindowOpened])

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={comboWindowOpened}
        onClose={closeMakeCombo}
      >
        <Grid className={classes.comboWrap}>
          <Card className={classes.categoriesWrap}>
            <Typography className={classes.comboTitle} variant="h5">{name}</Typography>
            <Grid className={classes.categories}>
              {categories}
            </Grid>
            <span>Стоимость: {total}руб</span>
            <Grid>
              <Button className={classes.addPizza}onClick={sendInfo} variant="contained" color="primary">
                Добавить
                </Button>
            </Grid>
          </Card>
          <Card className={classes.optionsWrap}>
            Выберите опцию слева
            </Card>
        </Grid>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => ({
  cart: state.cart
})
export default connect(mapStateToProps, { addComboToCart, updateComboInCart })(withStyles(styles)(MakeCombo));