import {
  PERCENT_DISCOUNT_COUPON,
  PEPPERONI_COUPON,
  BIRTHDAY_COUPON,
  FIRST_ORDER_COUPON,
  PROMOCODE_ERROR,
  ADD_PROMO_PIZZA,
  RETURN_INITIAL_PRODUCTS,
  PRODUCT_COUPON
} from '../types';

const initialState = {
  coupon_id: 0,
  success: false,
  coupon_name: '',
  error: '',
  discountValue: 0,
  type: '',
  code: '',
  value: 0,
  percent: {
    discount: 0,
  },
  firstOrder: {
    opened: false,
    product: {},
  },
}

const countDiscountPriceWithCombo = (products, discount) => {
  let productsWithoutCombo = products.filter(item => !item.combo);
  let price = productsWithoutCombo.reduce((total, product) => {
    return total += product.total
  }, 0);
  return Math.round(-(price * (discount / 100)))
}

export default function (state = initialState, { code, type, payload, totalPrice, products, coupon_name }) {
  switch (type) {
    case RETURN_INITIAL_PRODUCTS:
      return {
        ...initialState
      }
    case PRODUCT_COUPON:
      return {
        ...state,
        success: true,
        code,
        type: 'p',
        coupon_id: payload.coupon_history_id,
        coupon_name: payload.description
      }
    case PEPPERONI_COUPON:
      return {
        ...state,
        success: true,
        code,
        type: 'd',
        coupon_id: payload.coupon_history_id,
        coupon_name: payload.description
      }
    case BIRTHDAY_COUPON:
      return {
        ...state,
        success: true,
        code,
        type: 'h',
        coupon_id: payload.coupon_history_id,
        coupon_name: payload.description
      }
    case ADD_PROMO_PIZZA:
      return {
        ...state,
        firstOrder: {
          ...state.firstOrder,
          opened: false,
        }
      }
    case PROMOCODE_ERROR:
      return {
        ...state,
        error: payload
      }
    case FIRST_ORDER_COUPON:
      return {
        ...state,
        type: '1',
        code,
        success: true,
        coupon_id: payload.coupon_history_id,
        coupon_name: payload.description
      }
    case PERCENT_DISCOUNT_COUPON:
      return {
        ...state,
        code,
        success: true,
        type: payload.type,
        coupon_id: payload.coupon_history_id,
        coupon_name: payload.description,
        percent: {
          discount: payload.discount,
        },
        discountValue: countDiscountPriceWithCombo(products, payload.discount)
      }
    default:
      return state
  };
} 