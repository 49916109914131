import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, ...rest }) => {
  const authenticated = localStorage.FBIdToken;
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === undefined ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
}


AuthRoute.propTypes = {
  user: PropTypes.object
};

export default AuthRoute;