/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CartComment from '../CartComment';
import PizzaOptions from './PizzaOptions';
import PizzaSauces from './PizzaSauces';
import { backToList, addPizza, checkComboCombination, submitEditPizza } from '../../../redux/actions/cartActions';
//MUI
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
const styles = (theme) => ({
  ...theme.cartEdit
})

const PizzaDetails = ({ cart, classes, backToList, addPizza, checkComboCombination, submitEditPizza, promo }) => {
  const [openComment, setOpenComment] = useState(false); 
  const { editProduct, editProduct: { id, price, options, name } } = cart;
  const [error, setError] = useState('');
  const [finalPizza, setFinalPizza] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  const chooseOption = (productOption) => {
    setFinalPizza({
      ...finalPizza,
      activeOption: productOption,
      options: {
        ...finalPizza.options,
        pizza: {
          value: productOption.option_value_description.name,
          name: productOption.option_description.name,
          product_option_id: productOption.id
        },
      }
    })
  }

  useEffect(() => {
    let pizzaItem = {
      id,
      name,
      price,
      initial_price: price,
      comment: '',
      options: {},
      initial_options: options,
    }
    if (editProduct.activeOption) {
      pizzaItem = editProduct;
    }

    setFinalPizza(pizzaItem);
    setTotalPrice(pizzaItem.price);
  }, [editProduct])

  useEffect(() => {
    let finalPrice = finalPizza.initial_price;
    if (finalPizza.activeOption) {
      finalPrice = finalPrice + finalPizza.activeOption.price;
    }
    if (finalPizza.options && finalPizza.options.sauce) {
      finalPrice = finalPrice + finalPizza.options.sauce.price;
    }
    setTotalPrice(finalPrice);
  }, [finalPizza.activeOption, finalPizza.options])

  const chooseSauce = (e, sauceId, price) => {
    setFinalPizza({
      ...finalPizza,
      options: {
        ...finalPizza.options,
        sauce: {
          value: e.target.value,
          name: 'Соусы',
          product_option_id: sauceId,
          price,
        }
      }
    })
  }

  const addPizzaToCart = () => {
    if (!finalPizza.options.pizza) {
      setError('Выберите размер и вид теста');
      return;
    }
    let finalItem = {
      ...finalPizza,
      price: totalPrice,
    }
    if (editProduct.activeOption) {
      if (promo.percent.discount) {
        submitEditPizza(finalItem, promo.percent.discount);
        return;
      } else {
        submitEditPizza(finalItem);
        return;
      }
    }
    addPizza(finalItem);
    checkComboCombination();
  }

  const addComment = (comment) => {
    setFinalPizza({
      ...finalPizza,
      comment
    })
  }

  return (
    <>
      <CartComment defaultValue={finalPizza.comment} addComment={addComment} openComment={openComment} setOpenComment={setOpenComment} />
      <Grid className={classes.cartEditWrap}>
        <Typography className={classes.title} variant="h6">{name}</Typography>
        <Typography className={classes.priceMessage} variant='h5'>Стоимость: {totalPrice} руб.</Typography>
        <Grid>
          {
            finalPizza.initial_options ? finalPizza.initial_options.filter(item => item.option_description.name !== 'Соусы').map(item =>
              <PizzaOptions
                chosenOption={finalPizza.options && finalPizza.options.pizza ? finalPizza.options.pizza : {}}
                onChooseOption={chooseOption}
                key={item.id}
                option={item} />) : null
          }
        </Grid>
        <Grid>
          <Typography className={classes.title} variant="h6">Соусы</Typography>
          <RadioGroup className={classes.radioWrap} row >
            {
              finalPizza.initial_options ? finalPizza.initial_options.filter(item => item.option_description.name === 'Соусы').map(sauce => <PizzaSauces
                key={sauce.id}
                sauce={sauce}
                chooseSauce={chooseSauce}
                finalPizza={finalPizza}
              />) : null
            }
            <FormControlLabel
              checked={finalPizza.options && !finalPizza.options.sauce}
              onClick={() => setFinalPizza({
                ...finalPizza,
                options: {
                  ...finalPizza.options,
                  sauce: null
                }
              })}
              control={<Radio color="primary" />}
              label="без соуса" />
          </RadioGroup>
        </Grid>
        <Grid className={classes.buttonContainer}>
          <Button
            onClick={() => addPizzaToCart()}
            variant="contained"
            color="secondary">{editProduct.activeOption ? 'Изменить' : 'Добавить'}</Button>
          <Button variant="contained" onClick={() => setOpenComment(true)} color="secondary">Комментарий</Button>
        </Grid>
        <Typography className={classes.errorMessage} variant='h5'>{error}</Typography>
        <IconButton className={classes.closeEdit} onClick={() => backToList()}>
          <Clear />
        </IconButton>
      </Grid>
    </>
  )
}
const mapStateToProps = (state) => ({
  cart: state.cart,
  promo: state.promo
})
export default connect(mapStateToProps, { checkComboCombination, backToList, addPizza, submitEditPizza })(withStyles(styles)(PizzaDetails));
