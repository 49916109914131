import React, { useEffect } from 'react';
import ReusableTabs from 'components/ReusedTabs';
import WriteOffIngredientsTab from './WriteOffIngredients';
import WriteOffProductTab from './WriteOffProducts';
//MUI
import Card from '@material-ui/core/Card';
const WriteOffPage = () => {
  useEffect(() => {
    document.title = 'Списания'
  }, [])

  const tabsInfo = [
    {
      component: <WriteOffProductTab />,
      title: "Товары"
    },
  ]

  return (
    <Card>
      <ReusableTabs tabsContent={tabsInfo} />
    </Card>
  )
}

export default WriteOffPage
