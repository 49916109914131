import { ECHO_URI } from "../services";

export const echoOptions = {
  broadcaster: "pusher",
  key: 654987,
  cluster: "eu",
  wsHost: ECHO_URI,
  wsPort: 6001,
  wssPort: 6001,
  disableStats: true,
  encrypted: true,
  enabledTransports: ["ws", "wss"],
};
export const pusherOptions = {
  cluster: "eu",
  encrypted: true,
};
