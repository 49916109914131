import * as Yup from 'yup';

export default function blackPhoneSchema() {
  return Yup.object().shape({
    telephone: Yup.string()
      .required('Введите номер телефона'),
    reason: Yup.string()
      .required('Введите причину'),
  });
}
