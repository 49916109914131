/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import WriteOffTable                  from './WriteOffTable';
import { Context }                    from 'hooks/contextHook';
import { useHttp } from 'hooks/httpHook';
import DateSchedule from 'components/DateSchedule';
//MUI
import Card from '@material-ui/core/Card';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const WriteOffProducts = () => {
  const [dateState, setDateState] = useState({});
  const [list, setList] = useState({products: [], total: 0});
  const [products, setProducts] = useState([]);
  const { request, error } = useHttp();


  const getData = async () => {
    const data = await request('/admin/analytics/products/writeoffproduct/dates', 'post', dateState);
    if (data) {
      setList(data)
    }
  }

  const getProducts = async () => {
    const data = await request('/products/items', 'get');

    if (data) {
      setProducts(data);
    }
  }

  useEffect(() => {
    document.title = 'Списания';
    getData();
    getProducts();
  }, [dateState])

  return (
    <Grid>
      <Card>
        <CardContent>
          <CardHeader title="Списания" />
          <DateSchedule dateState={dateState} setDateState={setDateState} />
        </CardContent>
      </Card>
      <Context.Provider value={{ products }}>
        <WriteOffTable getData={getData} list={list} />
      </Context.Provider>
      <Snackbar
        open={!!error}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity="error">Произошла ошибка, попробуйте позже</Alert>
      </Snackbar>
    </Grid>
  )
}

export default WriteOffProducts
