/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';

const styles = theme => ({
  calculatorTop: {
    borderBottomLeftRadius: '10px'
  },
  dialogWrap: {
  },
  calculator: {
    flexBasis: '300px',
    height: '100%',
    padding: '35px'
  },
  block: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: "wrap",
    padding: '10px',
    borderRadius: '10px',
    background: '#9589A8',
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    color: 'white',
    fontWeight: '300',
  },
  mainSubtitleValue: {
    color: theme.palette.primary.light
  },
  subtitleValue: {
    color: '#FFFFFF',
  },
  gridContainer: {
    background: 'white',
    display: 'grid',
    padding: '2px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    gridGap: '12px',
  },
  calculatorButton: {
    background: '#CFCDCD',
    color: 'white',
    minHeight: '65px',
    fontSize: '30px',
    borderRadius: '15px',
    padding: '0px',
    '&:nth-child(12)': {
      gridRowStart: 3,
      gridRowEnd: 5,
      gridColumnStart: 4,
      gridColumnEnd: 4
    },
    '&:nth-child(13)': {
      gridColumnStart: 1,
      gridColumnEnd: 3
    },
    '&:hover': {
      background: theme.palette.primary.light
    },
    arrowBack: {
      fontSize: '50px'
    }
  }
})
const Calculator = ({ openCalculator, closeCalculator, cart, totalPrice, classes, price, setPrice, cashPayment }) => {
  const removeOneNumber = () => {
    setPrice(price.substring(0, price.length - 1));
  }

  const pressEqualSumm = (e) => {
    setPrice(totalPrice)
  }

  const pressNumber = (e) => {
    if (e.target.innerText === '0' && price === '') {
      return;
    }
    if (price.length > 6) {
      return;
    }
    setPrice(price + e.target.innerText);
  }

  const emptyField = () => {
    setPrice('');
  }

  useEffect(() => {
    if (Number(cart.order.cash) > 0) {
      setPrice(cart.order.cash)
    }
  }, [cart.order.cash])

  return (
    <Dialog
      className={classes.dialogWrap}
      open={openCalculator}
      onClose={closeCalculator}
    >
      <Grid className={classes.calculator}>
        <Grid className={classes.calculatorTop}>
          <Grid className={classes.block}>
            <Typography variant="h6" className={classes.subtitle}>Наличные</Typography>
            <Typography variant="h5" className={classes.subtitleValue}>{price}</Typography>
          </Grid>
          <Grid className={classes.block}>
            <Typography variant="h6" className={classes.subtitle}>Сдача</Typography>
            <Typography variant="h5" className={classes.subtitleValue}>{price < cart.totalPrice ? (0) : (price - cart.totalPrice)}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer}>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>7</Button>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>8</Button>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>9</Button>
          <IconButton onClick={() => removeOneNumber()} className={classes.calculatorButton}>
            <ArrowBack className={classes.arrowBack} />
          </IconButton>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>4</Button>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>5</Button>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>6</Button>
          <Button onClick={() => emptyField()} className={classes.calculatorButton}>AC</Button>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>1</Button>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>2</Button>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>3</Button>
          <Button onClick={() => cashPayment()} className={classes.calculatorButton}>OK</Button>
          <Button onClick={(e) => pressNumber(e)} className={classes.calculatorButton}>0</Button>
          <Button className={classes.calculatorButton} onClick={(e) => pressEqualSumm()}>=</Button>
        </Grid>
      </Grid>
    </Dialog>

  )
}

export default withStyles(styles)(Calculator);
