import React from 'react'
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import { connect } from 'react-redux';
const styles = (theme) => ({
  ...theme.mainStyles,
  traditional: {
    background: theme.palette.primary.dark,
    color: 'white'
  },
  thin: {
    background: theme.palette.primary.main,
    color: 'white'
  },
  activeOption: {
    background: theme.palette.secondary.main
  }
})
const ProductOptions = (props) => {
  const { classes, onChooseOption, chosenOption, option } = props;
  const { option_description, option_value_description } = option;
  const color = option_description.name === 'Традиционное' ? classes.traditional : classes.thin;
  const activeColor = chosenOption.product_option_id === option.id ? classes.activeOption : null
  const handleChooseOption = (option) => {
    onChooseOption(option);
  }
  return (
    <Button onClick={() => handleChooseOption(option)} variant="contained" className={`${classes.buttonWrap} ${color} ${activeColor} `}>
      <p>{option_description.name}</p>
      <p>{option_value_description.name}см</p>
    </Button>
  )
}

export default connect()(withStyles(styles)(ProductOptions));
