const createNewCount = (item, action) => {
  return {
    ...item,
    quantity: action === 'increment' ? item.quantity + 1 : item.quantity - 1,
    total: item.price * (action === 'increment' ? item.quantity + 1 : item.quantity - 1)
  }
}

const returnPromoItem = (payload) => {
  return {
    ...payload,
    render_id: payload.id,
    product_id: payload.id,
    total: payload.price * payload.quantity
  }
}

const returnPromoPizza = (payload) => {
  const { id, value, name } = payload.options[0];
  const pizzaOption = {
    product_option_id: id,
    name,
    value
  }
  return {
    ...payload,
    render_id: payload.id,
    product_id: payload.id,
    total: payload.price * payload.quantity,
    options: {
      pizza: pizzaOption
    }
  }
}

const updateComboKeys = (array, payload) => {
  let new_render_id = '';
  let description = [];
  array.forEach(product => {
    new_render_id += product.product_id;
    description.push(product.name)
  })
  description = description.join(', ')
  return payload = {
    ...payload,
    description,
    render_id: new_render_id
  }

}

const sortByOption = (arr) => {
  arr.sort((a, b) => a.option_id > b.option_id ? 1 : -1);
}

const returnChangedItem = (array, newItem, index, additionalItem) => {
  if (newItem.length) {
    return [
      ...array.slice(0, index),
      ...newItem,
      ...array.slice(index + 1),
    ]
  } else {
    return [
      ...array.slice(0, index),
      newItem,
      ...array.slice(index + 1),
    ]
  }
}

const createCartItem = (payload) => {
  const { id, name, price, options, description, combo, products, susi_combo } = payload;
  let chosenItem = {
    render_id: id,
    product_id: id,
    id,
    name,
    price,
    quantity: 1,
    options,
  }
  if (combo || susi_combo) {
    chosenItem = {
      ...chosenItem,
      combo: susi_combo ? true : combo,
      initial_products: products,
      description
    }
  }
  chosenItem.total = chosenItem.price * chosenItem.quantity;
  return chosenItem;
}

export {
  createCartItem,
  returnChangedItem,
  createNewCount,
  sortByOption,
  updateComboKeys,
  returnPromoPizza,
  returnPromoItem
}