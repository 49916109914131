import React, {useEffect} from 'react'
import InProgress from './InProgressOrders';
import Completed from './CompletedOrders';
import { pusherOptions } from '../../util/broadcastOptions';
import Pusher from 'pusher-js';
//MUI
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
  titleWrap: {
    display: 'flex',
  },
  titleBlock: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    textTransform: 'uppercase',
    paddingLeft: '2rem',
    fontSize: '2rem'
  },
  inProgress: {
    width: '40%'
  },
  inProgressTitle: {
    background: '#6200ea',
  },
  completed: {
    width: '60%'
  },
  finishedTitle: {
    background: '#ef6c00', 
  } 
})
const OrdersStatus = (props) => {
  const { classes } = props;
  useEffect(() => {
    window.Pusher = new Pusher('654987', pusherOptions);
  }, [])
  return (
    <Grid className={classes.titleWrap}>
      <InProgress />
      <Completed />
    </Grid>
  )
}

export default withStyles(styles)(OrdersStatus);
