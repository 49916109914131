/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Context } from 'hooks/contextHook';
import { useHttp } from 'hooks/httpHook';
import axios from 'axios';
//MUI
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {objectOf} from "prop-types";
//icons

const useStyles = makeStyles({
  variationWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    color: 'white',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  variation: {
    position: 'relative',
    display: 'flex',
    padding: '10px',
    width: '32%',
    flexDirection: 'column',
    marginBottom: '1rem',
    marginRight: '2%',
    '&:nth-child(3n+3)': {
      marginRight: 0,
    }
  },
  selectControl: {
    width: '100%',
    paddingBottom: '1rem',
  },
  deleteButton: {
    position: 'absolute',
    top: -10,
    right: -10,
  }
});

const startOptions = [
  {
    dough_type: 'Традиционное',
    dough_size: 25,
    price: 0,
  },
  {
    dough_type: 'Традиционное',
    dough_size: 30,
    price: 0,
  },
  {
    dough_type: 'Традиционное',
    dough_size: 35,
    price: 0,
  },
  {
    dough_type: 'Тонкое',
    dough_size: 30,
    price: 0,
  },
  {
    dough_type: 'Тонкое',
    dough_size: 35,
    price: 0,
  },
]

const startProducts = {
  objects: [],
  price: 0
}

export const PackagesWindow = ({ getData, packageItem, handleClose, open }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    name: '',
    options: startOptions,
    products: startProducts
  });
  const [errors, setErrors] = useState({
    name: '',
  });
  const [isProduct, setIsProduct] = useState(false);
  const [products, setProducts] = useState([]);
  const { request, loading, error, success, clearError } = useHttp();
  const { setUpdated } = useContext(Context);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeOption = (e, item) => {
    const value = e.target.value.replace(",", ".");
    let options = state.options;
    if (+item.dough_size === 30 || +item.dough_size === 35) {
      let thinId = options.findIndex((option) => option.dough_type === 'Тонкое' && +option.dough_size === +item.dough_size);
      options[thinId].price = value;
    }
    let targetId = options.findIndex(option => option.dough_size === item.dough_size && option.dough_type === item.dough_type);
    options[targetId].price = value;

    setState({
      ...state,
      products: startProducts,
      options
    })
  }

  function handleChangeSelect(i, items) {
    const objects = items.map(data => {
      return {
        id: data.id,
        name: data.name
      }
    })

    setState({
      ...state,
      options: startOptions,
      products: {
        ...state.products,
        objects
      },
    });
  }

  function handleChangePriceProduct(i) {
    setState({
      ...state,
      products: {
        ...state.products,
        price: i.target.value.replace(",", ".")
      }
    })
  }

  const sendProduct = async (e) => {
    e.preventDefault();
    if (!state.name) {
      setErrors({
        ...errors,
        name: 'Введите название'
      })
      return;
    }
    if (packageItem.id) {
      if (state.products.objects.length) {
        let body = {
          name: state.name,
          products: state.products
        }
        await request(`/admin/packages/${packageItem.id}`, 'put', body);
      } else {
        let body = {
          name: state.name,
          options: state.options,
          products: state.products
        }
        await request(`/admin/packages/${packageItem.id}`, 'put', body);
      }
    } else {
      if (state.products.objects.length) {
        let body = {
          name: state.name,
          products: state.products
        }
        await request('/admin/packages', 'post', body);
      } else {
        let body = {
          name: state.name,
          options: state.options,
          products: state.products,
        }
        await request('/admin/packages', 'post', body);
      }
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        clearError();
        handleClose();
        setState({
          name: '',
          options: [],
          products: []
        })
      }, 1500)
    }
  }, [success])

  useEffect(() => {
    if (packageItem.name) {
      setState({
        name: packageItem.name,
        options: packageItem.options,
        products: packageItem.products,
      })
    } else {
      setState({
        name: '',
        options: startOptions,
        products: startProducts
      })
    }
  }, [packageItem])

  useEffect(() => {
    if (success) {
      setUpdated(true);
      getData().then((res) => {
        setUpdated(false);
      });
    }
  }, [success])

  useEffect(() => {
    axios.get('/products', {
      params: {
        categories: [4, 5, 6]
      }
    }).then(function(res) {
      setProducts(res.data)
    }).catch(function(error) {
      console.log(error)
    })
  }, [])

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth='sm'
        onClose={() => {
          clearError();
          handleClose();
        }}
      >
        <DialogTitle>{packageItem.id ? `Редактирование ингредиента "${packageItem.name}"` : 'Добавление нового ингредиента'}</DialogTitle>
        <form onSubmit={sendProduct}>
          <DialogContent>
            <TextField
              name="name"
              onChange={handleChange}
              fullWidth
              value={state.name}
              label="Название"
              error={!!errors.name}
              helperText={errors.name}
            />

            <FormControlLabel
                control={<Switch checked={isProduct} onChange={() => setIsProduct(prev => !prev)} name="checkedA" />}
                label="Учитывать в товаре"
            />

            { isProduct ? (
              <FormControl error={!!errors.options} className={classes.selectControl}>
                <Card className={classes.variation}>
                  <Typography variant="subtitle1"></Typography>
                  <TextField
                      name="price"
                      value={state.products.price ? state.products.price : 0}
                      label="Цена (руб.)"
                      onChange={(i) => handleChangePriceProduct(i)}
                  />
                </Card>

                <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="tags-standard"
                    options={products}
                    getOptionLabel={(products) => products.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={(i, items) => handleChangeSelect(i, items)}
                    value={state.products.objects ? (state.products.objects.length ? state.products.objects : []) : []}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Выбрать товары"
                        />
                    )}
                />
            </FormControl>
            ) : (
              <FormControl error={!!errors.options} className={classes.selectControl}>
                <Grid className={classes.variationWrap}>
                  {
                    state.options.filter(item => item.dough_type !== 'Тонкое').map((item, index) => {
                      return (
                          (
                              <Card className={classes.variation} key={index}>
                                <Typography variant="subtitle1">{item.dough_size}</Typography>
                                <TextField
                                    name="price"
                                    value={item.price}
                                    label="Цена (руб.)"
                                    onChange={(e) => handleChangeOption(e, item)}
                                />
                              </Card>
                          )
                      )
                    })
                  }
                  <FormHelperText>{errors.options}</FormHelperText>
                </Grid>
              </FormControl>
            ) }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              clearError();
              handleClose();
            }} color="primary">
              Закрыть
            </Button>
            <div className={classes.wrapper}>
              <Button disabled={!!success} type="submit" className={classes.confirmButton} variant="contained" color="primary">
                Сохранить
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </form>
        <Snackbar
          open={!!error}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            severity="error">Произошла ошибка, попробуйте позже</Alert>
        </Snackbar>
        <Snackbar
          open={success}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            variant="filled"
            severity="success">{packageItem.id ? 'Упаковка успешно изменена' : 'Упаковка успешно добавлена'}</Alert>
        </Snackbar>
      </Dialog>

    </>
  );
}

export default PackagesWindow
