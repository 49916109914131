/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Scrollbars from 'react-custom-scrollbars';
import OrderItem from './OrderItem';
import { useHttp } from 'hooks/httpHook';
import { useTime } from 'hooks/timeHook';
const styles = theme => ({
  orderTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1)
  },
  orderContent: {
    width: '100%',
    minHeight: '200px',
    height: '428px',
    background: '#F3F3F3',
    borderRadius: '10px',
    padding: theme.spacing(1),
    position: 'relative'
  },
  orderWrap: {
    padding: theme.spacing(2),
    minWidth: '600px',
    minHeight: '500px',
  },
  payContainer: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  orderInfo: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    width: '60%',

  },
  infoContainer: {
    width: '100%',
    background: '#E5DDF2',
    borderRadius: '10px',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    color: '#6A6A6A'
  }
})


const getPlatformName = (platform_id) => {
  switch (platform_id) {
    case 1:
      return 'CRM';
    case 2:
      return 'vk';
    case 3:
      return 'Delivery Club';
    case 4:
      return 'Yandex';
    default:
      return 'сайт'
  }
}

const OrderWindow = ({ classes, id, open, setOpen }) => {
  const { formatDate } = useTime();
  const [order, setOrder] = useState({});
  const { request, loading, error } = useHttp();
  const [platform, setPlatform] = useState('');
  const { shipping_address, shipping_method, porch, floor, porch_code, flat, first_name, telephone, pay_method } = order;
  const handleClose = () => {
    setOpen(false)
  }

  const getOrderData = async () => {
    const data = await request(`/admin/orders/${id}`, 'get');
    if (data) {
      setOrder(data);
      setPlatform(() => getPlatformName(data.platform_id))
    }
  }

  useEffect(() => {
    if (id) {
      getOrderData();
    }
  }, [id])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      {
        loading ? <p>загрузка...</p> : (
          <Grid className={classes.orderWrap}>
            <Grid className={classes.orderTitle}>
              <Typography variant="h6">Заказ №{order.invoice_no} - {platform}</Typography>
              <Typography variant="h6">Создан в {formatDate(order.created_at, 'HH:mm')}</Typography>
            </Grid>
            <Grid className={classes.orderContent}>
              <Grid className={classes.orderList}>
                <Scrollbars autoHeight
                  autoHeightMin={200}
                  autoHeightMax={300}
                >
                  {
                    order.options && order.options.map((item, index) => <OrderItem key={item.id} item={item} last={index + 1 === order.options.length} />)
                  }
                </Scrollbars>
              </Grid>
              <Grid className={classes.orderInfo}>
                <Grid className={classes.infoContainer}>
                  <Typography variant="body1">{first_name ? `${first_name}, ` : ''} {telephone || ''}</Typography>
                </Grid>
                <Grid className={classes.infoContainer}>
                  {
                    shipping_method === 'delivery' ? (
                      <>
                        <Typography variant="body1"> {shipping_method === 'delivery' ? `${shipping_address}, подъезд ${!porch ? 'не указан' : porch}, этаж ${!floor ? 'не указан' : floor}, квартира ${!flat ? 'не указана' : flat}` : 'Самовывоз'}</Typography>
                        <Typography variant="body1">Домофон: {!porch_code ? 'не указан' : porch_code}</Typography>
                      </>
                    ) : 'Самовывоз'
                  }
                </Grid>
              </Grid>
              <Grid className={classes.payContainer}>
                <Button variant="contained" style={{ marginBottom: '0.5rem' }} disabled={true}>
                  {pay_method === 'cod' ? 'наличные' : 'карта'}
                </Button>
                <Button color="primary" variant="contained">
                  Итого: {order.total}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </Dialog>
  )
}

export default withStyles(styles)(OrderWindow)
