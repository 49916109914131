/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import red from '@material-ui/core/colors/red';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Typography from '@material-ui/core/Typography';
const styles = theme => ({
  confirmButton: {
    float: 'right',
    margin: '0 0.5rem 0.5rem 0'
  },
  removeButton: {
    position: 'absolute',
    right: 0,
    top: '15%',
  },
  variation: {
    position: 'relative',
    display: 'flex',
    padding: '10px',
    width: '100%',
    flexDirection: 'column',
    marginBottom: '1rem',
    marginRight: '1.9%',
    '&:nth-child(3n+3)': {
      marginRight: 0,
    }
  },
})

const IngredientEdit = ({ product, classes, ingredients, item = {}, loading, changeIngredientOnServer, removeIngredient }) => {
  const [globalIngredient, setGlobalIngredient] = useState({});
  const [ingredient, setIngredient] = useState(item);
  const [isPizza, setIsPizza] = useState(true);

  useEffect(() => {
    if (item && ingredients) {
      let targetIngredient = ingredients.find(a => a.id === item.id);
      setGlobalIngredient(targetIngredient);
    }
  }, [ingredients])

  useEffect(() => {
    if (!item.id) {
      return;
    }
    let targetIngredient = ingredients.find(a => a.id === item.id);
    setGlobalIngredient(targetIngredient);

    if (!product.options[0].dough_size) {
      setIsPizza(false)
      if (item.options.length) {
        setIngredient(item);
        return;
      }
      setIngredient({
        ...item,
        options: [
          {
            weight: 0
          }
        ]
      })
      return;
    }

    if (item.options && !item.options.length) {
      setIngredient({
        ...item,
        price: targetIngredient.price,
        name: targetIngredient.name,
        options: targetIngredient.options
      })
    } else {
      setIngredient({
        ...item,
        price: targetIngredient.price
      })
    }
  }, [item])

  const changeIngredientOption = (e, option) => {
    let newIngredient = JSON.parse(JSON.stringify(ingredient))
    if (newIngredient.options && !newIngredient.options[0].dough_size) {
      let value = e.target.value.replace(",", ".");
      const target = newIngredient.options;
      target[0].weight = value;
      setIngredient({
        ...newIngredient,
        is_global: 0,
        options: target
      })
      return;
    }

    const value = e.target.value.replace(",", ".");
    if (newIngredient.name === 'Тесто') {
      if (+option.dough_size === 25 || +option.dough_size === 30) {
        let thinId = newIngredient.options.findIndex((a) => a.dough_type === 'Тонкое' && +a.dough_size === +option.dough_size + 5);
        newIngredient.options[thinId].weight = value;
      };
    } else {
      if (+option.dough_size === 30 || +option.dough_size === 35) {
        let thinId = newIngredient.options.findIndex((a) => a.dough_type === 'Тонкое' && +a.dough_size === +option.dough_size);
        newIngredient.options[thinId].weight = value;
      }
    }
    let targetId = newIngredient.options.findIndex(a => Number(a.dough_size) === Number(option.dough_size) && a.dough_type === option.dough_type);
    newIngredient.options[targetId].weight = value;
    setIngredient({
      ...newIngredient
    })
  }

  const changeIngredientStatus = () => {
    if (!ingredient.is_global) {
      setIngredient({
        ...ingredient,
        is_global: 1,
        options: globalIngredient.options
      })
    } else {
      setIngredient({
        ...ingredient,
        is_global: 0,
      })
    }
  }

  return (
    <Card style={{ height: '100%' }}>
      {
        item.name ? (
          <>
            {
              isPizza ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(ingredient.is_global)}
                      onChange={changeIngredientStatus}
                      name="checkedB"
                      color="primary"
                      disabled={ingredient.options && !ingredient.options[0].dough_size}
                    />
                  }
                  label="Использовать данные из таблицы ингредиентов"
                />
              ) : null
            }
            <Grid style={{ padding: '5px' }}>
              {
                ingredient.options ? ingredient.options.filter((a) => a.dough_type !== 'Тонкое').map((item) => {
                  let optionPrice = (globalIngredient.price / 1000 * item.weight).toFixed(2);
                  return (
                    <Card className={classes.variation} variant="outlined" key={item.dough_size + item.dough_type}>
                      <Typography style={{ padding: '0 0 5px 5px' }} variant="subtitle1">{item.dough_size}</Typography>
                      <TextField
                        disabled={isPizza ? Boolean(ingredient.is_global) : false}
                        variant="outlined"
                        name="weight"
                        value={item.weight}
                        label="Вес (г)"
                        onChange={(e) => changeIngredientOption(e, item)}
                      />
                      <Typography variant="subtitle1">{optionPrice}руб.</Typography>
                    </Card>
                  )
                }) : null
              }
            </Grid>
            <Button onClick={() => changeIngredientOnServer(ingredient)} color="primary" variant="contained" className={classes.confirmButton} disabled={loading}>Сохранить</Button>
            <Button onClick={() => removeIngredient(ingredient)} style={{ background: red[400], color: 'white' }} variant="contained" className={classes.confirmButton} disabled={loading}>Удалить</Button>
          </>
        ) : null
      }
    </Card>
  )
}

export default withStyles(styles)(IngredientEdit)