import React, {useEffect}  from 'react'
import Grid from '@material-ui/core/Grid'; 
import axios from 'axios';
import OrderItem from '../OrderItem';
import Echo from "laravel-echo";
import { echoOptions } from '../../../util/broadcastOptions';
import {setProgressOrders} from '../../../redux/actions/dataActions';
import {connect} from 'react-redux';
//MUI
import withStyles from '@material-ui/core/styles/withStyles';


const styles = (theme) => ({
  ...theme.mainStyles,
  inProgress: {
    width: '40%',
    overflow: 'auto',
    height: '500px',
  },
  inProgressTitle: {
    background: theme.palette.primary.main,
  },
})
const InProgress = (props) => {
  const {data: {tvOrders}, setProgressOrders} = props;
  const { store_id } = localStorage
  useEffect(() => {
    window.Echo = new Echo(echoOptions); 
    window.Echo.channel(`products_${store_id}`).listen('.waiting', (e) => {
      setProgressOrders(e.products.original);
    });  
    axios({
      url: '/orders/waiting',
      method: 'get',
    })
      .then(res => {
        setProgressOrders(res.data);
      })
      .catch(err => console.log(err));
  }, [])
  const orderContent = !tvOrders.progress ? <p>Загрузка</p> : tvOrders.progress.map(item => <OrderItem data={item} key={item.id}/>)
  const { classes } = props;
  return (
    <Grid className={classes.inProgress}>
      <Grid className={`${classes.titleBlock} ${classes.inProgressTitle}`}>
        <h1>Готовятся</h1>
      </Grid>
      {orderContent}
    </Grid>
  )
}
const mapStateToProps = (state) => ({
  data: state.data
})
export default connect(mapStateToProps, {setProgressOrders})(withStyles(styles)(InProgress));
