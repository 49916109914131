import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import validationSchema from './schema';
import EyeInputField from '../../../../components/EyeInputField';
//MUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
const styles = theme => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
  },
})

const UserWindow = ({ open, handleClose, classes, getUsers, user }) => {
  const { first_name, last_name, user_name, password, email, password_confirmation, user_group_id, user_position_id } = user;
  const [placement, setPlacement] = useState([]);
  const [position, setPosition] = useState([]);
  useEffect(() => {
    axios.get('admin/groups')
      .then(res => setPlacement(res.data))
      .catch(err => console.error(err));
    axios.get('admin/positions')
      .then(res => setPosition(res.data))
      .catch(err => console.error(err));
  }, [])
  let positionContent = position ? position.map(item => {
    return (
      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
    )
  }) : null;
  let placementContent = placement ? placement.map(item => {
    return (
      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
    )
  }) : null;
  const formik = useFormik({
    initialValues: {
      first_name: first_name || '',
      last_name: last_name || '',
      user_name: user_name || '',
      email: email || '',
      password: password || '',
      password_confirmation: password_confirmation || '',
      user_group_id: user.first_name ? user_group_id.id : '',
      user_position_id: user.first_name ? user_position_id.id : '',
    }, 
    enableReinitialize: true,
    validationSchema: validationSchema(!user.first_name),
    onSubmit: values => {
      if (!user.first_name) {
        axios.post('/admin/users', {
          ...values,
          status: 1
        })
          .then(() => {
            getUsers();
            handleClose()
          })
          .catch(err => console.error(err.response.data));
      } else {
        axios.put(`/admin/users/${user.id}`, { ...values, status: 1 })
          .then((res) => {
            getUsers();
            handleClose();
          })
          .catch(err => console.error(err));
      }
    }
  })
  const title = !user.first_name ? 'Добавление нового пользователя' : `Редактирования пользователя ${formik.values.first_name}`
  const button = !user.first_name ? 'Добавить' : `Изменить`
  return (
    <div>
      <Dialog fullWidth={true}
        maxWidth='sm' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}> 
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent> 
            <TextField
              margin="dense"
              name="first_name"
              label="Имя"
              type="text"
              fullWidth
              onBlur={formik.handleBlur}
              error={formik.touched.first_name && formik.errors.first_name ? true : false}
              helperText={formik.touched.first_name && formik.errors.first_name ? formik.errors.first_name : null}
              onChange={formik.handleChange}
              value={formik.values.first_name}
            />
            <TextField
              margin="dense"
              name="last_name"
              label="Фамилия"
              type="text"
              fullWidth
              onBlur={formik.handleBlur}
              error={formik.touched.last_name && formik.errors.last_name ? true : false}
              helperText={formik.touched.last_name && formik.errors.last_name ? formik.errors.last_name : null}
              onChange={formik.handleChange}
              value={formik.values.last_name}
            />
            <TextField
              margin="dense"
              name="user_name"
              label="Логин"
              type="text"
              fullWidth
              onBlur={formik.handleBlur}
              error={formik.touched.user_name && formik.errors.user_name ? true : false}
              helperText={formik.touched.user_name && formik.errors.user_name ? formik.errors.user_name : null}
              onChange={formik.handleChange}
              value={formik.values.user_name}
            />
            <TextField
              margin="dense"
              name="email"
              label="Почта"
              type="email"
              fullWidth
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email ? true : false}
              helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <EyeInputField configs={{
              onBlur: formik.handleBlur,
              error: formik.touched.password && formik.errors.password ? true : false,
              helperText: formik.touched.password && formik.errors.password ? formik.errors.password : null,
              onChange: formik.handleChange,
              value: formik.values.password,
              name: 'password',
              label: 'Пароль'
            }} /> 
            <EyeInputField configs={{
              onBlur: formik.handleBlur,
              error: formik.touched.password_confirmation && formik.errors.password_confirmation ? true : false,
              helperText: formik.touched.password_confirmation && formik.errors.password_confirmation ? formik.errors.password_confirmation : null,
              onChange: formik.handleChange,
              value: formik.values.password_confirmation,
              name: 'password_confirmation',
              label: 'Повторите пароль'
            }} />  
            <FormControl className={classes.formControl}>
              <InputLabel id="placement">Должность</InputLabel>
              <Select
                labelId="placement"
                name="user_group_id"
                fullWidth
                onBlur={formik.handleBlur}
                error={formik.touched.user_group_id && formik.errors.user_group_id ? true : false}
                onChange={formik.handleChange}
                value={formik.values.user_group_id}
              >
                {placementContent}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="position">Позиция</InputLabel>
              <Select
                labelId="position"
                name="user_position_id"
                fullWidth
                onBlur={formik.handleBlur}
                error={formik.touched.user_position_id && formik.errors.user_position_id ? true : false}
                onChange={formik.handleChange}
                value={formik.values.user_position_id}
              >
                {positionContent}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Закрыть
          </Button>
            <Button variant="contained" type="submit" color="primary">
              {button}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(UserWindow)