import React, { useEffect, useState } from "react";
import { useHttp } from "hooks/httpHook";

import TableCell       from "@material-ui/core/TableCell";
import TableRow        from "@material-ui/core/TableRow";
import withStyles      from "@material-ui/core/styles/withStyles";
import CustomerWindow  from "./CustomerWindow";
import Typography      from "@material-ui/core/Typography";
import TableBody       from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Toolbar         from "@material-ui/core/Toolbar";

const styles = (theme) => ({
  warning: {
    color: "#ff0000",
    fontWeight: "bold",
  },
});

const CustomerBaseTable = ({
  classes,
  rowsPerPage,
  page,
  customerType,
  customersBase,
  order,
  orderBy,
  search,
}) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState([]);

  const date = (date) => {
    return new Date(date.replace(/-/g, "/")).toLocaleDateString("ru");
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function changeCutomersType(type) {
    switch (type) {
    case "all":
      return customersBase[0].all;
    case "less_one_month":
      return customersBase[0].less_one_month;
    case "less_two_month":
      return customersBase[0].less_two_month;
    case "less_three_month":
      return customersBase[0].less_three_month;
    case "in_month":
      return customersBase[0].in_month;
    case "one_all":
      return customersBase[0].one_all;
    case "one_less_one_month":
      return customersBase[0].one_less_one_month;
    case "one_less_two_month":
      return customersBase[0].one_less_two_month;
    case "one_less_three_month":
      return customersBase[0].one_less_three_month;
    case "one_in_month":
      return customersBase[0].one_in_month;
    case "medium_all":
      return customersBase[0].medium_all;
    case "medium_less_one_month":
      return customersBase[0].medium_less_one_month;
    case "medium_less_two_month":
      return customersBase[0].medium_less_two_month;
    case "medium_less_three_month":
      return customersBase[0].medium_less_three_month;
    case "medium_in_month":
      return customersBase[0].medium_in_month;
    case "max_all":
      return customersBase[0].max_all;
    case "max_less_one_month":
      return customersBase[0].max_less_one_month;
    case "max_less_two_month":
      return customersBase[0].max_less_two_month;
    case "max_less_three_month":
      return customersBase[0].max_less_three_month;
    case "max_in_month":
      return customersBase[0].max_in_month;
    }
  }

  const handleSearch = () => {
    return stableSort(
      changeCutomersType(customerType),
      getComparator(order, orderBy)
    ).filter (e => {
      const regex = new RegExp(search, 'gi')
      if(typeof e.telephone == "string")
        return e.telephone.match(regex)
      else
        return false
    })
  }

  return (
    <>
      <TableBody>
        <CustomerWindow item={item} handleClose={handleClose} open={open} />

        {handleSearch().length ? (
          handleSearch()
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow
                    hover
                    onClick={() => {
                      setItem(row);
                      handleClickOpen();
                    }}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      {row.first_name || (
                        <span className={classes.warning}>Отсутствует</span>
                      )}
                    </TableCell>
                    <TableCell align="right">{row.telephone}</TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">{date(row.last_order)}</TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
        ) : (
          <React.Fragment>
            <TableRow style={{height: "1300px"}} />
          </React.Fragment>
        )}
      </TableBody>
    </>
  );
};

export default withStyles(styles)(CustomerBaseTable);
