import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { withStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
const styles = (theme) => ({
  root: {
    width: '100%',
  },
  panel: {
    color: 'white'
  },
  selected: {}
})

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    background: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.light,
    transition: '0.3s',
    '&:hover': {
      color: theme.palette.primary.light,
      opacity: 1,
    },
    '&$selected': {
      fontSize: '1rem',
      color: 'white',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    height: '2px'
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const FullWidthTabs = (props) => {
  const { classes, tabsContent } = props;
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  const content = tabsContent.map((item, index) => {
    return (
      <TabPanel key={index} value={value} index={index} dir={theme.direction} >
        {item.component}
      </TabPanel>
    )
  });

  const tabLabels = tabsContent.map((tab, index) => {
    return (
      <AntTab key={index} label={tab.title} {...a11yProps(index)} />
    )
  });

  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary">
        <StyledTabs 
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          aria-label="full width tabs example"
        >
          {tabLabels}
        </StyledTabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {content}
      </SwipeableViews>
    </div>
  );
}

export default withStyles(styles)(FullWidthTabs);