import React, { useState } from 'react'
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';
import ruLocale from "date-fns/locale/ru";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DateFnsUtils from '@date-io/date-fns';
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles({
  dateInput: {
    paddingRight: '1rem'
  }
});


const TimeSchedule = () => {

  const classes = useStyles();

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const TimeScheduleContent = () => {
    return (
      <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
        <Divider />
        <TimePicker
          className={classes.dateInput}
          clearable
          ampm={false}
          label="Время"
          value={selectedTime}
          onChange={setSelectedTime}
        />
        <DatePicker
          className={classes.dateInput}
          variant="inline"
          name="date_end"
          autoOk={true}
          format="dd MMMM"
          label="Дата доставки"
          onChange={setSelectedDate}
          value={selectedDate}
        />
      </MuiPickersUtilsProvider>
    )
  }
  return {
    setSelectedTime, setSelectedDate, TimeScheduleContent, selectedTime, selectedDate
  }

}

export default TimeSchedule
