import React, { useState } from 'react'
import UserTableSkeleton from '../../../../util/skeletons/UserTableSkeleton';
import ConfirmAction from '../../../../components/ConfirmAction';
import { connect } from 'react-redux';
import { getUsers } from '../../../../redux/actions/adminActions';
import UserWindow from '../UserWindow';

//MUI
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import withStyles from '@material-ui/core/styles/withStyles';
//MUI icons
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import EditIcon from '@material-ui/icons/Edit';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

const styles = theme => ({
  ...theme.tables,
  addButton: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1)
  }
})
const UserTable = ({ deleted, rows, classes, getUsers }) => {
  const [user, setUser] = useState({});
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRecover, setOpenRecover] = useState(false);

  const handleOpenAdd = (user = {}) => {
    setUser(user);
    setOpenAdd(true)
  }

  const handleCloseAdd = () => {
    setOpenAdd(false)
  }

  const handleOpenDelete = (user) => {
    setOpenDelete(true);
    setUser(user)
  }
  const handleCloseRecover = () => {
    setOpenRecover(false)
  }
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenRecover = (user) => {
    setOpenRecover(true);
    setUser(user)
  }

  const deleteConfig = {
    method: 'delete',
    open: openDelete,
    handleClose: handleCloseDelete,
    question: `Вы действительно хотите удалить пользователя ${user.first_name}?`,
    updateList: getUsers,
    url: `/admin/users/${user.id}`
  }
  const recoverConfig = {
    method: 'PATCH',
    open: openRecover,
    handleClose: handleCloseRecover,
    question: `Вы действительно хотите восстановить пользователя ${user.first_name}?`,
    updateList: getUsers,
    url: `/admin/users/return/${user.id}`
  }
  return (
    <>
      <UserWindow getUsers={getUsers} user={user} open={openAdd} handleClose={handleCloseAdd} />
      <ConfirmAction config={deleteConfig} />
      <ConfirmAction config={recoverConfig} />
      <Table >
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Имя</TableCell>
            <TableCell className={classes.tableHeader} align="right">Почта</TableCell>
            <TableCell className={classes.tableHeader} align="right">Должность</TableCell>
            <TableCell className={classes.tableHeader} align="right">Позиция</TableCell>
            <TableCell className={classes.tableHeader} align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 ? <UserTableSkeleton /> : rows.map((user) => {
            const { first_name, last_name, id, email, user_group_id, user_position_id, status } = user;
            let fullName = `${first_name} ${last_name}`;
            const userActions = status ? (
              <>
                <IconButton onClick={() => handleOpenAdd(user)} >
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => handleOpenDelete(user)}>
                  <DeleteSweepIcon />
                </IconButton>
              </>
            ) : (
                <IconButton onClick={() => handleOpenRecover(user)}>
                  <ReplayIcon />
                </IconButton>
              )
            return (
              <TableRow hover key={id}>
                <TableCell component="th" scope="row">
                  {fullName}
                </TableCell>
                <TableCell align="right">{email}</TableCell>
                <TableCell align="right">{user_group_id.name}</TableCell>
                <TableCell align="right">{user_position_id.name}</TableCell>
                <TableCell align="right">
                  {userActions}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {!deleted ? (
        <Button className={classes.addButton} onClick={() => handleOpenAdd()} color="primary" variant="contained">Добавить нового сотрудника</Button>
      ) : null}

    </>
  )
}

export default connect(null, { getUsers })(withStyles(styles)(UserTable))