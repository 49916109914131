/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import ReactEcharts from 'echarts-for-react';

//MUI 
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  pieContent: {
    width: '100%'
  },
  piesWrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
})

const PieDiagram = ({ classes, data }) => {
  const [titles, setTitles] = useState([]);
  const [pieData, setPieData] = useState([]);
  const option = {
    legend: {
      orient: 'vertical',
      left: 10,
      data: titles
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: true
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '15',
            fontWeight: 'bold',
            formatter: function (params) {
              return `${params.name} - ${params.value}`
            },
            textStyle: {
              baseline: 'top'
            }
          }
        },
        data: pieData,
      }
    ]
  }

  useEffect(() => {
    if (data.titles) {
      setTitles(data.titles);
      setPieData(data.data)
    }
  }, [data])

  return (
    <ReactEcharts
      option={option}
      opts={{ renderer: 'svg' }}
    />
  )
}

export default withStyles(styles)(PieDiagram)
