/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { echoOptions, pusherOptions } from '../../util/broadcastOptions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { startServiceOrder, setProgressOrders, setReadyOrders, setHoldingOrders, startOrder, startDeliveryOrder } from '../../redux/actions/dataActions';
import CashOrder from './CashOrder';
import CashOrderInfo from './CashOrderInfo';
import axios from 'axios';
import { useHttp } from 'hooks/httpHook';
//MUI 
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  ...theme.mainStyles,
  gridContainer: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridGap: '20px',
    padding: theme.spacing(2)
  },
  gridInProgress: {
    gridArea: '1 / 1 / 2 / 8',
    padding: theme.spacing(1)
  },
  gridDone: {
    gridArea: '2 / 1 / 3 / 5',
    padding: theme.spacing(1)
  },
  gridHold: {
    gridArea: '2 / 5 / 3 / 8',
    padding: theme.spacing(1)
  },
  progressOrdersWrap: {
    display: 'grid',
    height: '100%',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    overflow: 'auto',
    gridGap: '10px',
  },
  holdOrdersWrap: {
    display: 'grid',
    height: '100%',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    overflow: 'auto',
    gridGap: '10px',
  },
  doneOrdersWrap: {
    display: 'grid',
    height: '100%',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    overflow: 'auto',
    gridGap: '10px',
  },
  orders: {
    width: '70%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  cashTitle: {
    fontSize: '30px',
  },
})

const Cash = (props) => {
  const { startDeliveryOrder, startOrder, classes, data, setHoldingOrders, setProgressOrders, setReadyOrders, startServiceOrder } = props;
  const { request } = useHttp();
  const getReadyOrders = async () => {
    const data = await request('/orders/completed', 'get');
    setReadyOrders(data);
  }
  const getHoldingOrders = async () => {
    const data = await request('/orders/time', 'get');
    setHoldingOrders(data);
  }

  const getProgressOrders = async () => {
    const data = await request('/orders/waiting', 'get');
    setProgressOrders(data);
  }
  const [orderInfo, setOrderInfo] = useState({});
  const openOrderInfo = (order) => {
    setOrderInfo(order);
  }
  const clickStartOrder = () => {
    axios.get('/orders/add')
      .then(() => {
        startOrder()
      })
      .catch((err) => console.log(err.response.data))
  }
  useEffect(() => {
    document.title = 'Заказы - Marti\'s CRM'
    let echo = new Echo(echoOptions);
    let pusher = new Pusher('654987', pusherOptions);
    const { store_id } = localStorage;
    echo.channel(`products_${store_id}`).listen('.waiting', (e) => {
      setProgressOrders(e.products.original);
    });

    echo.channel(`product_${store_id}`).listen('.completed', (e) => {
      setReadyOrders(e.products.original);
    });

    echo.channel(`time_${store_id}`).listen('.toTime', (e) => {
      setHoldingOrders(e.products.original);
    });

    getReadyOrders();
    getHoldingOrders();
    getProgressOrders();

    return () => {
      pusher.disconnect();
      echo.leave(`products_${store_id}`);
      echo.leave(`product_${store_id}`);
      echo.leave(`time_${store_id}`);
    }
  }, [])

  const progressContent = !data.tvOrders.progress ? <CircularProgress /> : data.tvOrders.progress.length === 0 ? <p>Заказов нет</p> : data.tvOrders.progress.map(item => <CashOrder openOrderInfo={openOrderInfo} key={item.id} data={item} />)
  const readyContent = !data.tvOrders.ready ? <CircularProgress /> : data.tvOrders.ready.length === 0 ? <p>Заказов нет</p> : data.tvOrders.ready.map(item => <CashOrder setOrderInfo={setOrderInfo} openOrderInfo={openOrderInfo} done={true} key={item.id} data={item} />)
  const holdingContent = !data.tvOrders.holding ? <CircularProgress /> : data.tvOrders.holding.length === 0 ? <p>Заказов нет</p> : data.tvOrders.holding.map(item => <CashOrder setOrderInfo={setOrderInfo} openOrderInfo={openOrderInfo} hold={true} key={item.id} data={item} />)
  return (
    <Grid className={classes.gridContainer}>
      <Card className={classes.gridInProgress}>
        <Typography className={classes.cashTitle}>Готовятся</Typography>
        <Grid className={classes.progressOrdersWrap}>
          {progressContent}
        </Grid>
      </Card>
      <Card className={classes.gridHold}>
        <Typography className={classes.cashTitle}>В ожидании</Typography>
        <Grid className={classes.holdOrdersWrap}>
          {holdingContent}
        </Grid>
      </Card>
      <Card className={classes.gridDone}>
        <Typography className={classes.cashTitle}>Готовы</Typography>
        <Grid className={classes.doneOrdersWrap}>
          {readyContent}
        </Grid>
      </Card>
      <CashOrderInfo startServiceOrder={startServiceOrder} startDeliveryOrder={startDeliveryOrder} startOrder={clickStartOrder} order={orderInfo} setOrder={setOrderInfo} />
    </Grid >
  )
}
const mapStateToProps = state => ({
  data: state.data
})
export default connect(mapStateToProps, { startServiceOrder, startDeliveryOrder, startOrder, setHoldingOrders, setProgressOrders, setReadyOrders })(withStyles(styles)(Cash))
