/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import SearchInput from 'components/SearchInput';
import TableSkeleton from 'util/skeletons/UserTableSkeleton';
import PrimecostItem from './PrimecostItem';
//MUI

import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Toolbar from '@material-ui/core/Toolbar';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
//icons
const styles = theme => ({
  ...theme.tables,
  tableTools: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cellName: {
    transition: '0.3s',
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  collapseActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1)
  }
})

const PrimecostTable = ({ classes, getData, products, loading }) => {
  const [value, setValue] = useState('');
  const [active, setActive] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const collapseRef = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <CardContent style={{ paddingTop: '2rem' }}>
        <Toolbar className={classes.tableTools}>
          <SearchInput setValue={setValue} />
        </Toolbar>
        <Table size="small" className={classes.table} >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Название</TableCell>
              <TableCell className={classes.tableHeader}>Себестоимость</TableCell>
              <TableCell className={classes.tableHeader} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? products.length ? products
              .filter((item) => {
                let name = item.name.toLowerCase();
                let inputValue = value.toLowerCase();
                if (name.indexOf(inputValue) + 1) {
                  return item;
                } else {
                  return null;
                }
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => {
                return <PrimecostItem
                  key={item.id}
                  active={active}
                  setActive={setActive}
                  collapseRef={collapseRef}
                  item={item}
                />
              }) : null : <TableSkeleton />}
          </TableBody>
        </Table>
         <TablePagination
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
          labelRowsPerPage="Элементов на странице"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>
    </>
  )
}

export default withStyles(styles)(PrimecostTable);
