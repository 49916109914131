import React, { useState, useEffect } from 'react';
import { Context } from 'hooks/contextHook';
import IngredientsCard from './IngredientsCard';
import ProductsTable from './ProductsTable';
//MUI
import Grid from '@material-ui/core/grid';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  ...theme.mainStyles,
})

const IngredientsPage = ({ classes }) => {
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    document.title = 'Продукты'
  }, [])
  return (
    <Context.Provider value={{
      updated, setUpdated
    }}>
      <Grid className={classes.flexbox}>
        <IngredientsCard setUpdated={setUpdated} />
        <ProductsTable />
      </Grid>
    </Context.Provider>
  )

}

export default withStyles(styles)(IngredientsPage)
