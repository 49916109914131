import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
const styles = theme => ({
  orderItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1)
  }
})

const OrderItem = ({ classes, item, last }) => {
  return (
    <>
      <Grid className={classes.orderItem}>
        <Typography>
          {item.name} {item.options && item.options.length ? " - " + item.options[0].value + "см " + item.options[0].name : null}
        </Typography>
        <Typography>
          {item.price}
        </Typography>
      </Grid>
      <Divider />
    </>
  )
}

export default withStyles(styles)(OrderItem)