/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import TableSkeleton from 'util/skeletons/UserTableSkeleton';
import ConfirmAction from 'components/ConfirmAction';
import SearchInput from 'components/SearchInput';
import BlockPhoneWindow from './BlockPhoneWindow';
//MUI 
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
//icons
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteSweep';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
    ...theme.tables,
    tableTools: {
        display: 'flex',
        justifyContent: 'space-between'
    },
})

const PhoneBlackList = ({ getPhones, phones, classes, loading }) => {
    const [value, setValue] = useState('');
    const [person, setPerson] = useState({});
    const [openAdd, setOpenAdd] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const handleOpenDelete = (item) => {
        setPerson(item)
        setOpenDelete(true);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const deleteMethods = {
        method: 'DELETE',
        open: openDelete,
        handleClose: handleCloseDelete,
        question: `Вы действительно хотите удалить номер ${person.telephone} из черного списка?`,
        updateList: getPhones,
        url: `/blacklist/${person.id}`
    }

    return (
        <>
            <BlockPhoneWindow openAdd={openAdd} setOpenAdd={setOpenAdd} getBlackList={getPhones} />
            <ConfirmAction config={deleteMethods} />
            <CardHeader title={'Черный список'} />
            <CardContent>
                <Toolbar className={classes.tableTools}>
                    <SearchInput setValue={setValue} />
                    <Button onClick={() => {
                        setOpenAdd(true);
                    }} color="primary" variant="contained">
                        <AddIcon />
                    </Button>
                </Toolbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Номер телефона</TableCell>
                            <TableCell align="right">Причина</TableCell>
                            <TableCell align="right">Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? phones
                            .filter((item) => {
                                let telephone = item.telephone.toLowerCase();
                                let inputValue = value.toLowerCase();
                                if (telephone.indexOf(inputValue) + 1) {
                                    return item;
                                } else {
                                    return null;
                                }
                            })
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item) => (
                                <TableRow key={item.telephone}>
                                    <TableCell component="th" scope="item">
                                        {item.telephone}
                                    </TableCell>
                                    <TableCell align="right">{item.reason}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => handleOpenDelete(item)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )) : <TableSkeleton />}
                    </TableBody>
                </Table>
                <TablePagination
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                    labelRowsPerPage="Элементов на странице"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={phones.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </CardContent>
        </>
    )
}

export default withStyles(styles)(PhoneBlackList)
