/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Context } from 'hooks/contextHook';
import { useHttp } from 'hooks/httpHook';
//MUI
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  variationWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    color: 'white',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  variation: {
    position: 'relative',
    display: 'flex',
    padding: '10px',
    width: '32%',
    flexDirection: 'column',
    marginBottom: '1rem',
    marginRight: '2%',
    '&:nth-child(3n+3)': {
      marginRight: 0,
    }
  },
});

const startOptions = [
  {
    dough_type: 'Традиционное',
    dough_size: 25,
    weight: 0,
  },
  {
    dough_type: 'Традиционное',
    dough_size: 30,
    weight: 0,
  },
  {
    dough_type: 'Традиционное',
    dough_size: 35,
    weight: 0,
  },
  {
    dough_type: 'Тонкое',
    dough_size: 30,
    weight: 0,
  },
  {
    dough_type: 'Тонкое',
    dough_size: 35,
    weight: 0,
  },
]

export const ConstituentsWindow = ({ classes, getData, constituent, constituent: { name, options }, handleClose, open }) => {
  const { request, loading, error, success, clearError } = useHttp();
  const { setUpdated } = useContext(Context);
  const [state, setState] = useState({
    name: '',
    price: '',
    options: startOptions
  });

  const [errors, setErrors] = useState({
    select: '',
    name: '',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeOption = (e, item) => {
    const value = e.target.value.replace(",", ".");
    let options = state.options;
    if (item.dough_size === 25 || item.dough_size === 30) {
      let thinId = options.findIndex((option) => option.dough_type === 'Тонкое' && option.dough_size === item.dough_size + 5);
      options[thinId].weight = value;
    };
    let targetId = options.findIndex(option => option.dough_size === item.dough_size && option.dough_type === item.dough_type);
    options[targetId].weight = value;

    setState({
      ...state,
      options
    })
  }
  const sendProduct = async (e) => {
    e.preventDefault();
    if (!state.name) {
      setErrors({
        ...errors,
        name: 'Введите название'
      })
      return;
    }
    if (!state.price) {
      setErrors({
        ...errors,
        price: 'Введите цену'
      })
      return;
    }
    if (constituent.id) {
      await request(`/admin/constituents/${constituent.id}`, 'put', state);
    } else {
      await request('/admin/constituents ', 'post', state);
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        clearError();
        handleClose();
        setState({
          name: '',
          price: 0,
          options: []
        })
      }, 1500)
    }
  }, [success])


  useEffect(() => {
    if (constituent.name) {
      setState({
        name: constituent.name,
        price: constituent.price,
        options: constituent.options,
      })
    } else {
      setState({
        name: '',
        price: 0,
        options: startOptions
      })
    }
  }, [constituent])

  useEffect(() => {
    if (success) {
      setUpdated(true);
      getData();
    }
  }, [success])

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          clearError();
          handleClose();
          setState({
            name: '',
            price: 0,
            options: startOptions,
          })
        }}
      >
        <DialogTitle>{constituent.id ? `Редактирование составляющего "${constituent.name}"` : 'Добавление нового составляющего'}</DialogTitle>
        <form onSubmit={sendProduct}>
          <DialogContent>
            <TextField
              name="name"
              onChange={handleChange}
              fullWidth
              value={state.name}
              label="Название"
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              name="price"
              onChange={handleChange}
              style={{ marginBottom: '20px' }}
              fullWidth
              value={state.price}
              label="Цена за килограмм"
              error={!!errors.price}
              helperText={errors.price}
            />
            <Grid className={classes.variationWrap}>
              {
                state.options.filter(item => item.dough_type !== 'Тонкое').map((item, index) => {
                  let optionPrice = state.price ? (state.price / 1000 * item.weight).toFixed(2) : 0;
                  return (
                    (
                      <Card className={classes.variation} key={index}>
                        <Typography variant="subtitle1">{item.dough_size}</Typography>
                        <TextField
                          name="weight"
                          value={item.weight}
                          label="Вес (г)"
                          onChange={(e) => handleChangeOption(e, item)}
                        />
                        <Typography variant="body2">{optionPrice}руб.</Typography>
                      </Card>
                    )
                  )
                })
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              clearError();
              handleClose();
            }} color="primary">
              Закрыть
            </Button>
            <div className={classes.wrapper}>
              <Button type="submit" className={classes.confirmButton} variant="contained" color="primary">
                Сохранить
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </form>
        <Snackbar
          open={!!error}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            severity="error">Произошла ошибка, попробуйте позже</Alert>
        </Snackbar>
        <Snackbar
          open={success}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            variant="filled"
            severity="success">{constituent.id ? 'Составляющее успешно изменен' : 'Составляющее успешно добавлено'}</Alert>
        </Snackbar>
      </Dialog>

    </>
  );
}

export default withStyles(styles)(ConstituentsWindow)
