import * as Yup from 'yup';

export default function CouponFormSchema() {
  return Yup.object().shape({
    description: Yup.string()
    .required('Введите название промокода')
    .min(1, 'Слишком короткое название'),
    code: Yup.string()
    .required('Введите код'),
    discount: Yup.string()
    .required('Введите размер дисконта (только число)'),
    total: Yup.string()
    .required('Введите минимальную сумму заказа'),
    quantity: Yup.string()
    .required('Количество промокодов'),
    type: Yup.string()
    .required('Выберите тип скидки'),
    is_hold_telephone: Yup.string()
    .required('Выберите статус привязки к телефону'),
    date_start: null,
    date_end: null
  });
}
