import React, { useState } from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import ActionsCollapse from 'components/ActionsCollapse';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
//icons
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  cellName: {
    transition: '0.3s',
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
})
const PrimecostItem = ({ active, setActive, classes, collapseRef, item, item: { primecost, id, name } }) => {
  const [cost, setCost] = useState(primecost);
  const config = {
    initial_value: cost,
    path: `admin/products/${id}/primecost`,
    method: +primecost > 0 ? 'put' : 'post',
    bodyKey: 'price'
  }
  const changeCost = (value) => {
    setCost(value)
  }
  return (
    <React.Fragment key={id}>
      <TableRow ref={collapseRef} className={classes.cellName} key={name} onClick={(e) => {
        if (active === id) {
          setActive(null)
        } else {
          setActive(id)
        }
      }}>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        <TableCell component="th" scope="row">
          {cost}руб.
            </TableCell>
        <TableCell align="right">
          <IconButton style={{ transition: '.2s', transform: active === id ? 'rotate(180deg)' : 'rotate(0)' }} size="small">
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={active === id} timeout="auto" unmountOnExit>
            <ActionsCollapse onSubmit={changeCost} config={config} label="Себестоимость" item={item} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default withStyles(styles)(PrimecostItem)
