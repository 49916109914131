import React, {useState} from 'react';
import IconButton        from '@material-ui/core/IconButton';
import ConfirmAction     from '../../../../components/ConfirmAction';
import axios             from 'axios';
import OrderWindow       from './OrderWindow'
import EditorWindow       from './EditorWindow'
//MUI
import InputLabel        from '@material-ui/core/InputLabel';
import MenuItem          from '@material-ui/core/MenuItem';
import FormControl       from '@material-ui/core/FormControl';
import Select            from '@material-ui/core/Select';
import Card              from '@material-ui/core/Card';
import CardContent       from '@material-ui/core/CardContent';
import Table             from '@material-ui/core/Table';
import TableBody         from '@material-ui/core/TableBody';
import TableCell         from '@material-ui/core/TableCell';
import TableRow          from '@material-ui/core/TableRow';
import TableHead       from '@material-ui/core/TableHead';
import withStyles      from '@material-ui/core/styles/withStyles';
import TablePagination from '@material-ui/core/TablePagination';
import Typography      from "@material-ui/core/Typography";
import Button          from "@material-ui/core/Button";
import ButtonGroup     from "@material-ui/core/ButtonGroup";
import Toolbar         from "@material-ui/core/Toolbar";
//icons
import VisibilityIcon  from '@material-ui/icons/Visibility';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CreateIcon      from '@material-ui/icons/Create';
import Snackbar        from "@material-ui/core/Snackbar";
import Alert           from "@material-ui/lab/Alert";
import Dialog          from "@material-ui/core/Dialog";

//admin/orders
const styles = theme => ({
  resultContainer: {
    marginTop: theme.spacing(3)
  },
  formControl    : {
    width: '130px'
  }
})

const BaseTable = ({classes, ordersBase, getOrdersBase, dateState}) => {
  const [order, setOrder]           = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit]     = useState(false);
  const [openEditor, setOpenEditor]     = useState(false);
  const [method, setMethod]         = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [page, setPage]               = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleOpenDelete = (data) => {
    setOrder(data)
    setOpenDelete(true);
  };

  const handleOpenEdit = (data) => {
    setOrder(data);
    setOpenEdit(true);
  }

  const handleOpenEditor = (data) => {
    setOrder(data);
    setOpenEditor(true);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const deleteMethods = {
    method     : 'DELETE',
    open       : openDelete,
    handleClose: handleCloseDelete,
    question   : `Вы действительно хотите удалить заказ ${order.invoice_no}?`,
    updateList : () => getOrdersBase(dateState),
    url        : `/admin/orders/${order.id}`
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const changeStatus = (e, id) => {
    axios.put(`/admin/orders/${id}`, {order_status_id: e.target.value})
      .then(() => getOrdersBase(dateState))
      .catch(err => console.error(err.response.data))
  }

  function getMethod(params) {
    getOrdersBase(dateState, params)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, ordersBase.length - page * rowsPerPage);

  const handleSaveChange = result => {
    setSuccess(result)
    getOrdersBase(dateState)
  }

  const handleErrorChange = result => {
    setError(result)
  }

  return (
    <Card className={classes.resultContainer}>
      <ConfirmAction config={deleteMethods}/>
      <OrderWindow open={openEdit} setOpen={setOpenEdit} id={order.id}/>
      <EditorWindow open={openEditor} setOpen={setOpenEditor} id={order.id} success={handleSaveChange} error={handleErrorChange} errorText={setErrorText}/>
      <CardContent>
        {/*<TableToolbar />*/}
        <Toolbar disableGutters={true}>
          <ButtonGroup>
            <Button color="primary" variant={method === '' ? "outlined" : "contained"} onClick={() => {
              setMethod('')
              getMethod({})
            }}>
              Все заказы
            </Button>
            <Button color="primary" variant={method === 'take_away' ? "outlined" : "contained"} onClick={() => {
              setMethod('take_away')
              getMethod({shipping_method: 'take_away'})
            }}>
              Самовывоз
            </Button>
            <Button color="primary" variant={method === 'delivery' ? "outlined" : "contained"} onClick={() => {
              setMethod('delivery')
              getMethod({shipping_method: 'delivery'})
            }}>
              Доставка
            </Button>
          </ButtonGroup>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Номер заказа</TableCell>
              <TableCell className={classes.tableHeader} align="right">Сумма</TableCell>
              <TableCell className={classes.tableHeader} align="right">Телефон</TableCell>
              <TableCell className={classes.tableHeader} align="right">Промокод</TableCell>
              <TableCell className={classes.tableHeader} align="right">Способ получения</TableCell>
              <TableCell className={classes.tableHeader} align="right">Способ доставки</TableCell>
              <TableCell className={classes.tableHeader} align="right">Платформа</TableCell>
              <TableCell className={classes.tableHeader} align="right">Статус</TableCell>
              <TableCell className={classes.tableHeader} align="right">Дата создания</TableCell>
              <TableCell className={classes.tableHeader} align="right"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersBase.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => {
              const {id, invoice_no, total, telephone, order_status_id, created_at, shipping_method, platform_id, is_site, coupon} = order;
              return (
                <TableRow hover key={id}>
                  <TableCell component="th" scope="row">
                    {invoice_no}
                  </TableCell>
                  <TableCell align="right">{total || 'Отсутствует'}</TableCell>
                  <TableCell align="right">{telephone || 'Отсутствует'}</TableCell>
                  <TableCell align="right">{coupon || '-'}</TableCell>
                  <TableCell align="right">{shipping_method === 'take_away' ? <Typography variant={'body2'} component={'span'} color={"secondary"} style={{fontWeight: 'bold'}}>Самовывоз</Typography> : 'Доставка' }</TableCell>
                  <TableCell align="right">{shipping_method === 'take_away' ? '-' : platform_id === 1 ? "Курьер" : platform_id === 3 ? <Typography component={'span'} variant={'body2'} style={{color: "green"}}>Delivery</Typography> : platform_id === 4 ? <Typography component={'span'} variant={'body2'} color="secondary">Yandex</Typography> : ''}</TableCell>
                  <TableCell align="right">{is_site ? 'Сайт' : 'CRM'}</TableCell>
                  <TableCell align="right">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="status-label">Статус</InputLabel>
                      <Select
                        id="status"
                        value={order_status_id}
                        onChange={(e) => changeStatus(e, id)}
                      >
                        <MenuItem value={1}>Ожидание</MenuItem>
                        <MenuItem value={2}>Выполняется</MenuItem>
                        <MenuItem value={3}>Доставлено</MenuItem>
                        <MenuItem value={4}>Отменено</MenuItem>
                        <MenuItem value={5}>Возврат</MenuItem>
                        <MenuItem value={6}>Завершен</MenuItem>
                        <MenuItem value={7}>Ко времени</MenuItem>
                      </Select>
                    </FormControl></TableCell>
                  <TableCell align="right">{created_at}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleOpenEditor(order)}>
                      <CreateIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenEdit(order)}>
                      <VisibilityIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleOpenDelete(order)}>
                      <DeleteSweepIcon/>
                    </IconButton></TableCell>
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow style={{height: (53) * emptyRows}}>
                <TableCell colSpan={6}/>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
          labelRowsPerPage="Элементов на странице"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ordersBase.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {success === true && error === false ?
          <Snackbar
            open={true}
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
            <Alert severity="success">Данные успешно отправлены</Alert>
          </Snackbar>
         : success === false && error === true ?
          <Snackbar
            open={true}
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
            <Alert severity="error">{errorText}</Alert>
          </Snackbar>
            : null
        }
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(BaseTable)
