/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CouponWindow from './CouponWindow';
import ConfirmAction from '../../../components/ConfirmAction';
import UserTableSkeleton from '../../../util/skeletons/UserTableSkeleton';
import { setCoupons } from '../../../redux/actions/adminActions';
import { connect } from 'react-redux';
//MUI
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/core/styles/withStyles';
//MUI icons
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import EditIcon from '@material-ui/icons/Edit';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { useHttp } from 'hooks/httpHook';

const styles = theme => ({
  ...theme.tables,
  plus: {
    fontSize: '35px',
  },
  addPromoButton: {
    margin: '1rem',
  }
})
const Coupons = ({ classes, setCoupons, admin: { coupons } }) => {
  const { request, loading } = useHttp();
  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const getCoupons = async () => {
    const data = await request('/admin/coupons', 'get');
    if (data) {
      setCoupons(data);
    }
  }
  const handleOpenDelete = (couponData) => {
    setCoupon(couponData)
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  useEffect(() => {
    getCoupons()
    document.title = 'Купоны'
  }, [])

  const handleOpen = (coupon = {}) => {
    setCoupon(coupon);
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  }

  const deleteMethods = {
    method: 'DELETE',
    open: openDelete,
    handleClose: handleCloseDelete,
    question: `Вы действительно хотите удалить купон ${coupon.name}?`,
    updateList: getCoupons,
    url: `/admin/coupons/${coupon.id}`
  }
  return (
    <>
      <ConfirmAction config={deleteMethods} />
      <CouponWindow coupon={coupon} getCoupons={getCoupons} open={open} handleClose={handleClose} />
      <Card>
        <CardContent>
          <CardHeader title="Промокоды" />
          <Table >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Название промокода</TableCell>
                <TableCell className={classes.tableHeader} align="right">Код</TableCell>
                <TableCell className={classes.tableHeader} align="right">Тип</TableCell>
                <TableCell className={classes.tableHeader} align="right">Размер скидки</TableCell>
                <TableCell className={classes.tableHeader} align="right">Количество</TableCell>
                <TableCell className={classes.tableHeader} align="right">Сумма заказа</TableCell>
                <TableCell className={classes.tableHeader} align="right">Сроки</TableCell>
                <TableCell className={classes.tableHeader} align="right">Привязка к телефону</TableCell>
                <TableCell className={classes.tableHeader} align="right">Статус</TableCell>
                <TableCell className={classes.tableHeader} align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? <UserTableSkeleton /> : coupons.map((coupon) => {
                const { id, description, code, type, discount, total, date_start, date_end, quantity, status, is_hold_telephone } = coupon;
                let fullDate = `${date_start} - ${date_end}`;
                const couponActions = <>
                  <IconButton onClick={() => handleOpen(coupon)} >
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton onClick={() => handleOpenDelete(coupon)}>
                    <DeleteSweepIcon />
                  </IconButton>
                </>
                return (
                  <TableRow hover key={id}>
                    <TableCell component="th" scope="row">
                      {description}
                    </TableCell>
                    <TableCell align="right">{code}</TableCell>
                    <TableCell align="right">{type}</TableCell>
                    <TableCell align="right">{discount}</TableCell>
                    <TableCell align="right">{quantity}</TableCell>
                    <TableCell align="right">{total}</TableCell>
                    <TableCell align="right">{fullDate}</TableCell>
                    <TableCell align="right">{is_hold_telephone ? 'Есть' : 'Отсутствует'}</TableCell>
                    <TableCell align="right">{status ? 'Активен' : <p style={{color: '#f00', fontWeight: 'bold'}}>Выключен</p>}</TableCell>
                    <TableCell align="right">
                      {couponActions}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Fab onClick={() => handleOpen()} color="primary" aria-label="Новый заказ" className={classes.addPromoButton}>
            <AddIcon className={classes.plus} />
          </Fab>
        </CardContent>
      </Card>
    </>
  )
}
const mapStateToProps = state => ({
  admin: state.admin
})
export default connect(mapStateToProps, { setCoupons })(withStyles(styles)(Coupons))