/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab';
import { useHttp } from 'hooks/httpHook';
import TextField from '@material-ui/core/TextField';
import matchSorter from 'match-sorter';

const AddressAutoComplete = ({ streetAddress, setStreetAddress, townAddress, setTownAddress }) => {
  const [streetValues, setStreetValues] = useState([]);
  const [townValues, setTownValues] = useState([]);
  const [townId, setTownId] = useState(0);
  const { request, loading } = useHttp();

  const handleChangeTownAdress = async (value) => {
    setTownAddress(value);
  }

  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue);
  }

  const handleChangeStreetAdress = async (value) => {
    setStreetAddress(value);
  }

  const getStreets = async () => {
    let result = await request(`/streets/cities/${townId}`, 'post', { street: streetAddress });
    if (result) {
      setStreetValues(result);
    }
  }

  useEffect(() => {
    if (townValues.length > 0 && townAddress) {
      let targetTown = townValues.find(item => {
        if (item.name.indexOf(townAddress) >= 0) {
          return item;
        }
        return item;
      });
      let targetTownId = targetTown.id;
      setTownId(targetTownId);
    }
  }, [townValues])

  useEffect(() => {
    if (streetAddress && streetAddress.length >= 3) {
      getStreets()
    }
  }, [streetAddress])

  useEffect(() => {
    async function getTown() {
      let townResult = await request(`/cities?store_id=${localStorage.getItem('store_id')}`, 'get');

      let array = [];
      array[0] = {
        name: townResult.name,
        id: townResult.id
      }

      townResult = array;

      if (townResult.length === 1) {
        setTownAddress(townResult[0].name);
        setTownId(townResult[0].id);
        setTownValues(townResult);
      }
    }
    getTown();
  }, [])

  return (
    <>
      <Autocomplete
        inputValue={townAddress}
        options={townValues.map((option) => option.name)}
        name="town"
        clearOnBlur={false}
        loading={loading}
        handleHomeEndKeys
        style={{ flexBasis: '45%' }}
        onInputChange={(event, value) => {
          handleChangeTownAdress(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Город"
          />
        )}>
      </Autocomplete>
      <Autocomplete
        inputValue={streetAddress}
        options={streetValues.filter((item) => {
          if (!streetAddress) {
            return null;
          }
          if (streetAddress.length < 4) {
            return null;
          }
          return item;
        })}
        filterOptions={filterOptions}
        name="street"
        clearOnBlur={false}
        loading={loading}
        noOptionsText="Нет подходящих улиц"
        loadingText="Загрузка..."
        handleHomeEndKeys
        style={{ flexBasis: '45%' }}
        onChange={(event, value) => {
          if (!value) {
            setStreetAddress('');
            return;
          }
          setStreetAddress(value)
        }}
        onInputChange={(event, value) => {
          handleChangeStreetAdress(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Улица"
          />
        )}>
      </Autocomplete>
    </>
  )
}

export default AddressAutoComplete
