import {
  PERCENT_DISCOUNT_COUPON,
  ROUBLES_DISCOUNT_COUPON,
  PRODUCT_COUPON,
  PEPPERONI_COUPON,
  FIRST_ORDER_COUPON,
  BIRTHDAY_COUPON,
  SET_INITIAL_PRODUCTS
} from '../types';


export const setInitialProducts = () => dispatch => {
  dispatch({
    type: SET_INITIAL_PRODUCTS
  })
}
export const setDiscount = (data, products, client) => (dispatch) => {
  switch (data.type) {
    case "h":
      dispatch({
        type: BIRTHDAY_COUPON,
        payload: data,
        code: client.code
      });
      break;
    case "%":
      dispatch({
        type: PERCENT_DISCOUNT_COUPON,
        payload: data,
        totalPrice: client.totalPrice,
        products,
        code: client.code
      });
      break;
    case "-":
      dispatch({
        type: ROUBLES_DISCOUNT_COUPON,
        code: client.code
      });
      break;
  case "p":
      dispatch({
        type: PRODUCT_COUPON,
        payload: data,
        code: client.code
      });
      break;
    case "d":
      dispatch({
        type: PEPPERONI_COUPON,
        payload: data,
        code: client.code
      });
      break;
    case "1":
      dispatch({
        type: FIRST_ORDER_COUPON,
        payload: data,
        code: client.code
      });
      break;
    default:
      return;
  }
} 