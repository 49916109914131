import * as Yup from 'yup';

export default function UserFormSchema(bool) {
  return Yup.object().shape({
    first_name: Yup.string()
    .required('Введите имя'),
    last_name: Yup.string()
    .required('Введите фамилию'),
    user_name: Yup.string()
    .required('Введите логин'),
    email: Yup.string()
    .required('Введите электронную почту пользователя'),
    password: bool ? Yup.string()
    .required('Введите пароль') : null,
    password_confirmation: bool ? Yup.string()
    .required('Подтвердите пароль') : null,
    user_group_id: Yup.string()
    .required('Выберите должность'),
    user_position_id: Yup.string()
    .required('Выберите позицию'),
  });
}
