import {
  GET_COUPONS,
  GET_CUSTOMERS,
  GET_ORDERS_BASE,
  GET_USERS,
} from "../types";
import axios from "axios";

export const getUsers = () => (dispatch) => {
  axios
    .get("/admin/users")
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err.data));
};
export const setCoupons = (data) => (dispatch) => {
  dispatch({
    type: GET_COUPONS,
    payload: data,
  });
};

export const getOrdersBase = (dates, params = []) => (dispatch) => {
  if (!dates.date_start) {
    sendRequest({ now: 1 }, params);
  } else {
    sendRequest(dates, params);
  }

  function sendRequest(data, params) {
    if (params.shipping_method) {
      axios
        .post(
          "/admin/orders/find?shipping_method=" + params.shipping_method,
          data
        )
        .then((res) => {
          dispatch({
            type: GET_ORDERS_BASE,
            payload: res.data.reverse(),
          });
        })
        .catch((err) => console.log(err.response.data));
    } else {
      axios
        .post("/admin/orders/find", data)
        .then((res) => {
          dispatch({
            type: GET_ORDERS_BASE,
            payload: res.data.reverse(),
          });
        })
        .catch((err) => console.log(err.response.data));
    }
  }
};

export const getCustomers = (params) => (dispatch) => {
  let url = "/admin/customers";

  if (params) {
    url = "/admin/customers?coupon=" + params.coupon;
  }

  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_CUSTOMERS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err.response.data));
};
