/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask'
import { useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendDataToOrder } from '../../redux/actions/cartActions';
import { setOrderDate } from '../../redux/actions/dataActions';
import TimeSchedule from './timeSchedule';
import { useTime } from 'hooks/timeHook';
import { useFormik } from 'formik';
import validationSchema from './customerSchema';
import { useHttp } from 'hooks/httpHook';
import axios from 'axios';
import ConfirmAction from 'components/ConfirmAction';
import AddressAutoComplete from 'components/AddressAutoComplete'
//MUI 
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  ...theme.mainStyles,
  leftBlock: {
    width: '40%',
    padding: '15px'
  },
  rightBlock: {
    width: '50%',
    marginLeft: '10%',
    padding: '15px'
  },
  blockInfo: {
    width: '100%',
    paddingTop: '15px'
  },
  customerInput: {
    width: '100%',
    color: 'red',
  },
  textarea: {
    fontFamily: 'FuturaBookC',
    fontSize: '16px',
    width: '100%',
    marginTop: theme.spacing(1),
    padding: '10px',
    resize: 'none',
    transition: '0.5s',
    outline: 'none',
    border: '1px solid grey',
    borderRadius: '10px',
    '&:focus': {
      border: `1px solid ${theme.palette.primary.main}`,
    }
  },
  payButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    background: 'none',
  },
  calculatorHelper: {
    width: '65%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '2% 2% 2% 0'
  },
  calculatorButton: {
    background: '#e8e8e8',
    margin: '5px',
    width: '30%',
    height: '70px',
    fontSize: '25px',
    '&:hover': {
      background: theme.palette.primary.light
    },
  },
  customerButton: {
    right: '15px'
  },
  deleteButton: {
    display: 'block',
    position: 'absolute',
    bottom: theme.spacing(2),
    right: '270px',
  },
  cancelButton: {
    display: 'block',
    position: 'absolute',
    bottom: theme.spacing(2),
    right: '150px',
  },
  customerDataWrap: {
    display: 'flex',
    flexWrap: 'no-wrap',
    width: '100vw',
    height: '100vh',
    padding: theme.spacing(2)
  },
  customerDataContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
    gridGap: '20px',
  },
  gridBlock: {
    padding: theme.spacing(1)
  },
  personalInfo: {
    gridArea: '1 / 1 / 2 / 2',
  },
  adressInfo: {
    gridArea: '2 / 1 / 4 / 2',
  },
  payMethod: {
    gridArea: '1 / 2 / 2 / 3',
  },
  homeData: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  homeDataInput: {
    flexBasis: '45%'
  },
  confirmButton: {
    display: 'block',
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  commentBlock: {
    paddingTop: theme.spacing(2)
  }
})

const CustomerData = ({ setOrderDate, classes, sendDataToOrder, cart, data }) => {
  const { flat, porch, porch_code, floor, first_name, pay_method, shipping_method, comment, cash } = cart.order;
  const { selectedTime, selectedDate, setSelectedTime, setSelectedDate, TimeScheduleContent } = TimeSchedule();
  const { request, loading } = useHttp();
  const { formatDate } = useTime();
  const history = useHistory();
  const [orderTime, setOrderTime] = useState('Как можно раньше');
  const [streetAddress, setStreetAddress] = useState('')
  const [townAddress, setTownAddress] = useState('');
  const [date, setDate] = useState('');
  const [errorBlackList, setErrorBlackList] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    console.log(streetAddress)
    let time = formatDate(selectedTime, "HH:mm");
    let newDate = formatDate(selectedDate, 'YYYY-MM-DD');
    if (data.orderData.time) {
      setOrderTime('Ко времени');
      setSelectedTime(data.orderData.time);
      setSelectedDate(data.orderData.date);
      time = formatDate(data.orderData.time, "HH:mm");
      newDate = formatDate(data.orderData.date, 'YYYY-MM-DD');
    }
    setDate(newDate + " " + time);
  }, [selectedDate, selectedTime])

  useEffect(() => {
    if (data.orderData.street) {
      setTownAddress({
        id: data.orderData.id,
        town: data.orderData.town
      });
      setTownAddress(data.orderData.town);
      setStreetAddress(data.orderData.street);
    }
  }, [data.orderData])

  const deleteOrder = async () => {
    await axios.put(`orders/${data.orderId}`, { order_status_id: 4 })
      .catch(err => console.log(err.response.data));

    await axios.delete(`/admin/orders/${data.orderId}`)
      .catch(err => console.log(err.response.data))

    window.location.href = '/cash'
  }

  const makeOrderState = async () => {
    await setOrderDate({ time: selectedTime, date: selectedDate, street: streetAddress, town: townAddress, townId: 0 })
  }

  const prepareDeliveryInfo = () => {
    let data = {
      ...formik.values,
      time: date,
      shipping_address: streetAddress
    }
    let phone = data.telephone.replace(/[^+0-9]/gim, '');
    data.telephone = phone;

    if (orderTime === 'Как можно раньше') {
      data.time = null;
    }
    sendDataToOrder(data);
  }

  const formik = useFormik({
    initialValues: {
      //квартира
      flat: flat || '',
      //подъезд
      porch: porch || '',
      //домофон
      porch_code: porch_code || '',
      //этаж
      floor: floor || '',
      //имя
      first_name: first_name || '',
      //номер телефона
      telephone: cart.phone || '',
      //способ оплаты
      pay_method: pay_method || '',
      //способ доставки
      shipping_method: shipping_method || 'delivery',
      //комментарий
      comment: comment || '',
      cash: cash || ""
    },
    enableReinitialize: true,
    validationSchema: validationSchema(),
    onSubmit: async values => {

      if (!values.telephone) {
        setOpenConfirm(true);
        return;
      } else {
        const { store_id } = localStorage;
        let checkPhoneBL = await request('/blacklist/find', 'post', { telephone: values.telephone.replace(/[^+0-9]/gim, ''), store_id });
        if (checkPhoneBL.reason) {
          setErrorBlackList('Данный номер в черном списке');
          return;
        }
        const addressdata = {
          address: streetAddress,
          porch: values.porch,
          flat: values.flat,
          floor: values.floor,
          store_id
        }
        if (values.shipping_method === "delivery") {
          let checkAddressBL = await request('/blacklist/address/find', 'post', addressdata);
          if (checkAddressBL.reason) {
            setErrorBlackList('Данный адрес в черном списке');
            return;
          }
        }
      }
      await prepareDeliveryInfo();
      await makeOrderState();
      history.push('/cash/pay');
    }
  })

  useEffect(() => {
    setErrorBlackList('');
  }, [formik.values.telephone])

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  }

  const confirmConfig = {
    open: openConfirm,
    handleClose: handleCloseConfirm,
    question: `Перейти к оплате без оформления номера?`,
    updateList: async () => {
      await prepareDeliveryInfo();
      await makeOrderState();
      history.push('/cash/pay');
    },
  }

  return (
    <>
      <ConfirmAction config={confirmConfig} />
      <Grid className={classes.customerDataWrap}>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Grid className={classes.customerDataContent}>
            <Card className={`${classes.gridBlock} ${classes.personalInfo}`}>
              <FormLabel component="legend">Контактная информация</FormLabel>
              <TextField
                defaultValue={formik.values.first_name}
                label="Имя клиента"
                className={classes.customerInput}
                name="first_name"
                onChange={formik.handleChange}
              />
              <InputMask
                defaultValue={formik.values.telephone}
                name="telephone"
                mask="+7 (999) 999-99-99"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.telephone && formik.errors.telephone ? true : false}
                helperText={formik.touched.telephone && formik.errors.telephone ? formik.errors.telephone : null}
              >
                <TextField
                  label="Номер телефона"
                  className={classes.customerInput} />
              </InputMask>
              <FormControl error={!!errorBlackList}>
                <FormHelperText style={{ fontSize: '1.5rem' }}>{errorBlackList}</FormHelperText>
              </FormControl>
            </Card>
            <Card className={`${classes.gridBlock} ${classes.adressInfo}`}>
              <FormLabel component="legend">Адрес и комментарий</FormLabel>
              <FormLabel component="legend">Способ получения</FormLabel>
              <RadioGroup
                row
                onChange={formik.handleChange}
                value={formik.values.shipping_method}
                name="shipping_method">
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Самовывоз"
                  value="take_away"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Доставка"
                  value="delivery"
                  labelPlacement="end"
                />
              </RadioGroup>
              {
                formik.values.shipping_method === 'delivery' ? (
                  <Grid className={classes.homeData}>
                    <AddressAutoComplete
                      streetAddress={streetAddress}
                      townAddress={townAddress}
                      setStreetAddress={setStreetAddress}
                      setTownAddress={setTownAddress} />
                    <TextField
                      defaultValue={formik.values.porch}
                      label="Подъезд"
                      name="porch"
                      className={classes.homeDataInput}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.porch && formik.errors.porch ? true : false}
                      helperText={formik.touched.porch && formik.errors.porch ? formik.errors.porch : null}
                    />
                    <TextField
                      defaultValue={formik.values.porch_code}
                      label="Домофон"
                      name="porch_code"
                      className={classes.homeDataInput}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.porch_code && formik.errors.porch_code ? true : false}
                      helperText={formik.touched.porch_code && formik.errors.porch_code ? formik.errors.porch_code : null}
                    />
                    <TextField
                      defaultValue={formik.values.floor}
                      label="Этаж"
                      name="floor"
                      className={classes.homeDataInput}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.floor && formik.errors.floor ? true : false}
                      helperText={formik.touched.floor && formik.errors.floor ? formik.errors.floor : null}
                    />
                    <TextField
                      defaultValue={formik.values.flat}
                      label="Квартира"
                      name="flat"
                      className={classes.homeDataInput}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.flat && formik.errors.flat ? true : false}
                      helperText={formik.touched.flat && formik.errors.flat ? formik.errors.flat : null}
                    />
                  </Grid>
                ) : null
              }
              <Grid className={classes.commentBlock}>
                <FormLabel component="legend">Комментарий</FormLabel>
                <textarea
                  placeholder="Комментарий к заказу"
                  name="comment"
                  value={formik.values.comment}
                  className={classes.textarea}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Card>
            <Card className={`${classes.gridBlock} ${classes.payMethod}`}>
              <FormControl
                component="fieldset"
                error={formik.touched.pay_method && formik.errors.pay_method ? true : false}
              >
                <FormLabel component="legend" variant="h5">Способ оплаты</FormLabel>
                <RadioGroup
                  row
                  name="pay_method"
                  value={formik.values.pay_method}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="card"
                    control={<Radio color="primary" />}
                    label="Банковская карта"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="cod"
                    control={<Radio color="primary" />}
                    label="Наличные"
                    labelPlacement="end"
                  />
                </RadioGroup>
                <FormHelperText>{formik.touched.pay_method && formik.errors.pay_method ? formik.errors.pay_method : null}</FormHelperText>
              </FormControl>
              <Divider />
              <FormControl
                disabled={formik.values.pay_method === 'cod' ? false : true}
                component="fieldset"
              >
                <FormLabel component="legend" variant="h5">Сдача с:</FormLabel>
                <RadioGroup
                  row
                  name="cash"
                  value={formik.values.cash}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value={""}
                    control={<Radio color="primary" />}
                    label="Без сдачи"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="500"
                    control={<Radio color="primary" />}
                    label="500"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="1000"
                    control={<Radio color="primary" />}
                    label="1000"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="2000"
                    control={<Radio color="primary" />}
                    label="2000"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="5000"
                    control={<Radio color="primary" />}
                    label="5000"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Card>
            <Card className={classes.gridBlock}>
              <FormLabel component="legend">Время получения</FormLabel>
              <RadioGroup
                row
                value={orderTime}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Как можно раньше"
                  name="Как можно раньше"
                  value="Как можно раньше"
                  onChange={(e) => setOrderTime(e.target.value)}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Ко времени"
                  name="Ко времени"
                  value="Ко времени"
                  onChange={(e) => setOrderTime(e.target.value)}
                  labelPlacement="end"
                />
              </RadioGroup>
              {
                orderTime === 'Ко времени' ? <TimeScheduleContent /> : null
              }
            </Card>
            {
              !!errorBlackList ? (
                <Button
                  className={classes.deleteButton}
                  variant="contained"
                  onClick={deleteOrder}
                  color="primary">Удалить заказ</Button>
              ) : null
            }
            <Button
              component={Link}
              to="/cash/pay"
              disabled={!!errorBlackList}
              className={classes.cancelButton}
              variant="outlined"
              color="primary">Отмена</Button>
            <Button
              disabled={!!errorBlackList}
              type="submit"
              className={`${classes.confirmButton} ${classes.customerButton}`}
              variant="contained"
              color="primary">Завершить</Button>
          </Grid>
        </form>
      </Grid >
    </>
  )
}
const mapStateToProps = state => ({
  data: state.data,
  cart: state.cart
})

export default connect(mapStateToProps, { sendDataToOrder, setOrderDate })(withStyles(styles)(CustomerData))