import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import userReducer from './reducers/userReducer';
import dataReducer from './reducers/dataReducer';
import uiReducer from './reducers/uiReducer';
import cartReducer from './reducers/cartReducer'; 
import adminReducer from './reducers/adminReducer';
import promoReducer from './reducers/promoReducer';

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
    promo: promoReducer,
    user: userReducer,
    data: dataReducer,
    UI: uiReducer,
    cart: cartReducer,
    admin: adminReducer,
})

const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;