import React, { useState } from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import ActionsCollapse from 'components/ActionsCollapse';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import currency from 'util/currency';

//icons
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  cellName: {
    transition: '0.3s',
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },

})
const ComboItem = ({ active, setActive, classes, collapseRef, item, item: { percent, id, name } }) => {
  const [percentValue, setPercentValue] = useState(percent);
  let config = {
    initial_value: percentValue,
    path: `/admin/products/combos/${item.id}`,
    method: 'put',
    bodyKey: 'percent'
  }
  const changePercent = (value) => {
    setPercentValue(value)
  }

  return (
    (
      <React.Fragment key={item.name}>
        <TableRow ref={collapseRef} className={classes.cellName} key={item.name} onClick={(e) => {
          if (active === item.id) {
            setActive(null)
          } else {
            setActive(item.id)
          }
        }}>
          <TableCell component="th" scope="row">
            {item.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {percentValue || 0}%
              </TableCell>
          <TableCell align="right">
            <IconButton style={{ transition: '.2s', transform: active === item.id ? 'rotate(180deg)' : 'rotate(0)' }} size="small">
              <ExpandMoreIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={active === item.id} timeout="auto" unmountOnExit>
              <ActionsCollapse onSubmit={changePercent} config={config} label="Дисконт" item={item} />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  )
}

export default withStyles(styles)(ComboItem)
