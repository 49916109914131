/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Context } from 'hooks/contextHook';
import { useHttp } from 'hooks/httpHook';
//MUI
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
//icons

const useStyles = makeStyles({
  variationWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    color: 'white',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  variation: {
    position: 'relative',
    display: 'flex',
    padding: '10px',
    width: '32%',
    flexDirection: 'column',
    marginBottom: '1rem',
    marginRight: '2%',
    '&:nth-child(3n+3)': {
      marginRight: 0,
    }
  },
  selectControl: {
    width: '100%',
    paddingBottom: '1rem',
  },
  deleteButton: {
    position: 'absolute',
    top: -10,
    right: -10,
  }
});

const startOptions = [
  {
    dough_type: 'Традиционное',
    dough_size: 25,
    weight: 0,
  },
  {
    dough_type: 'Традиционное',
    dough_size: 30,
    weight: 0,
  },
  {
    dough_type: 'Традиционное',
    dough_size: 35,
    weight: 0,
  },
  {
    dough_type: 'Тонкое',
    dough_size: 30,
    weight: 0,
  },
  {
    dough_type: 'Тонкое',
    dough_size: 35,
    weight: 0,
  },
]

export const IngredientWindow = ({ getData, ingredient, ingredient: { name, options }, handleClose, open }) => {
  const classes = useStyles();
  const { setUpdated } = useContext(Context);
  const [state, setState] = useState({
    name: '',
    price: '',
    options: startOptions
  });
  const [errors, setErrors] = useState({
    name: '',
    price: '',
  });
  const { request, loading, error, success, clearError } = useHttp();


  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeOption = (e, item) => {
    const value = e.target.value.replace(",", ".");
    let options = state.options;
    if (name === 'Тесто') {
      if (+item.dough_size === 25 || +item.dough_size === 30) {
        let thinId = options.findIndex((option) => option.dough_type === 'Тонкое' && +option.dough_size === +item.dough_size + 5);
        options[thinId].weight = value;
      };
    } else {
      if (+item.dough_size === 30 || +item.dough_size === 35) {
        let thinId = options.findIndex((option) => option.dough_type === 'Тонкое' && +option.dough_size === +item.dough_size);
        options[thinId].weight = value;
      }
    }
    let targetId = options.findIndex(option => option.dough_size === item.dough_size && option.dough_type === item.dough_type);
    options[targetId].weight = value;

    setState({
      ...state,
      options
    })
  }

  const sendProduct = async (e) => {
    e.preventDefault();
    if (!state.name) {
      setErrors({
        ...errors,
        name: 'Введите название'
      })
      return;
    }
    if (!state.price) {
      setErrors({
        ...errors,
        price: 'Введите цену'
      })
      return;
    }
    if (ingredient.id) {
      await request(`/admin/ingredients/${ingredient.id}`, 'put', state);
    } else {
      await request('/admin/ingredients', 'post', state);
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        clearError();
        handleClose();
        setState({
          name: '',
          price: '',
          options: []
        })
      }, 1500)
    }
  }, [success])

  useEffect(() => {
    if (ingredient.name) {
      setState({
        name: ingredient.name,
        price: ingredient.price,
        options: ingredient.options
      })
    } else {
      setState({
        name: '',
        price: 0,
        options: startOptions

      })
    }
  }, [ingredient])

  useEffect(() => {
    if (success) {
      setUpdated(true);
      getData();
    }
  }, [success])

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth='sm'
        onClose={() => {
          clearError();
          handleClose();
        }}
      >
        <DialogTitle>{ingredient.id ? `Редактирование ингредиента "${ingredient.name}"` : 'Добавление нового ингредиента'}</DialogTitle>
        <form onSubmit={sendProduct}>
          <DialogContent>
            <TextField
              name="name"
              disabled={state.name === 'Тесто'}
              onChange={handleChange}
              fullWidth
              value={state.name}
              label="Название"
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              name="price"
              disabled={state.name === 'Тесто'}
              onChange={handleChange}
              style={{ marginBottom: '20px' }}
              fullWidth
              value={state.price}
              label="Цена за килограмм"
              error={!!errors.price}
              helperText={errors.price}
            />
            <FormControl error={!!errors.options} className={classes.selectControl}>
              <Grid className={classes.variationWrap}>
                {
                  state.options.filter(item => item.dough_type !== 'Тонкое').map((item, index) => {
                    let optionPrice = state.price ? (state.price / 1000 * item.weight).toFixed(2) : 0;
                    return (
                      (
                        <Card className={classes.variation} key={index}>
                          <Typography variant="subtitle1">{item.dough_size}</Typography>
                          <TextField
                            disabled={state.name === 'Тесто'}
                            name="weight"
                            value={item.weight}
                            label="Вес (г)"
                            onChange={(e) => handleChangeOption(e, item)}
                          />
                          <Typography variant="body2">{optionPrice}руб.</Typography>
                        </Card>
                      )
                    )
                  })
                }
                <FormHelperText>{errors.options}</FormHelperText>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              clearError();
              handleClose();
            }} color="primary">
              Закрыть
            </Button>
            <div className={classes.wrapper}>
              <Button disabled={state.name === 'Тесто'} type="submit" className={classes.confirmButton} variant="contained" color="primary">
                Сохранить
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </form>
        <Snackbar
          open={!!error}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            severity="error">Произошла ошибка, попробуйте позже</Alert>
        </Snackbar>
        <Snackbar
          open={success}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            variant="filled"
            severity="success">{ingredient.id ? 'Составляющее успешно изменен' : 'Составляющее успешно добавлено'}</Alert>
        </Snackbar>
      </Dialog>

    </>
  );
}

export default IngredientWindow
