import { LOADING_UI, CLEAR_ERRORS, SET_ERRORS } from "../types";

import axios from "axios";

export const loginUser = (userData, history) => (dispatch) => {
  dispatch({
    type: LOADING_UI,
  });
  axios
    .post("/login", userData)
    .then((res) => {
      setAuthorizationHeader(res.data.message);
      dispatch({
        type: CLEAR_ERRORS,
      });
      const profPath = `/${localStorage.path}`;
      history.push(profPath);
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

const setAuthorizationHeader = (message) => {
  const { token, path, store_id } = message;
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);
  localStorage.setItem("path", path);
  localStorage.setItem("store_id", store_id);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
  axios.get("/products");
};
