import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CHANGE_QUANTITY,
  BACK_TO_LIST,
  ADD_PIZZA_TO_CART,
  EDIT_PIZZA,
  EDIT_COMBO,
  SUBMIT_EDIT_PIZZA,
  SET_PHONE,
  SUBMIT_PAYMENT,
  TOGGLE_SUCCESS_PAYMENT,
  OPEN_PRODUCT_INFO,
  CLOSE_PRODUCT_INFO,
  MAKE_REORDER,
  ADD_PROMO_PIZZA,
  RECOUNT_TOTAL_PRICE,
  RETURN_INITIAL_PRODUCTS,
  CHECK_COMBO_COMBINATION,
  CLOSE_MAKE_COMBO,
  ADD_COMBO_TO_CART,
  UPDATE_COMBO_IN_CART,
  SEND_DATA_TO_ORDER,
  FIRST_TIME_ADD,
  CHANGE_COMMENT,
  CHANGE_PRICE_THROUGH_SERVICE
} from '../types';

export const changePriceThroughService = (id, value) => {
  return {
    type: CHANGE_PRICE_THROUGH_SERVICE,
    payload: {
      id,
      value
    }
  }
}
export const addProductToCart = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: product,
  })
}

export const closeMakeCombo = () => {
  return {
    type: CLOSE_MAKE_COMBO
  }
}

export const checkComboCombination = () => {
  return {
    type: CHECK_COMBO_COMBINATION
  }
}

export const editPizza = (product) => (dispatch) => {
  dispatch({
    type: EDIT_PIZZA,
    payload: product
  })
}

export const editCombo = (payload) => {
  return {
    type: EDIT_COMBO,
    payload
  }
}

export const updateComboInCart = payload => {
  return {
    type: UPDATE_COMBO_IN_CART,
    payload
  }
}

export const submitEditPizza = (pizza, discount) => (dispatch) => {
  dispatch({
    type: SUBMIT_EDIT_PIZZA,
    payload: {
      product: pizza,
      oldPrice: pizza.oldPrice,
      previousId: pizza.id,
    },
    discount,
  })
}
export const addPizza = (newItem) => (dispatch) => {
  dispatch({
    type: ADD_PIZZA_TO_CART,
    payload: newItem
  })
}

export const deleteProduct = (productId) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: productId
  })
}

export const changeQuantity = (pizzaPrice, productId, changeType) => (dispatch) => {
  dispatch({
    type: CHANGE_QUANTITY,
    payload: productId,
    changeType,
    pizzaPrice
  })
}
export const sendDataToOrder = (payload) => dispatch => {
  dispatch({
    type: SEND_DATA_TO_ORDER,
    payload
  })
}
export const backToList = () => dispatch => {
  dispatch({
    type: BACK_TO_LIST
  })
}
export const addComboToCart = (payload) => {
  return {
    type: ADD_COMBO_TO_CART,
    payload
  }
}
export const setClientPhone = (phone) => (dispatch) => {
  dispatch({
    type: SET_PHONE,
    payload: phone
  })
}

export const submitPayment = () => async dispatch => {
  await dispatch({
    type: SUBMIT_PAYMENT
  })
}

export const toggleSuccessPayment = (bool) => dispatch => {
  dispatch({
    type: TOGGLE_SUCCESS_PAYMENT,
    successPay: bool
  })
}
export const openProductInfo = (itemInfo) => dispatch => {
  dispatch({
    type: OPEN_PRODUCT_INFO,
    payload: itemInfo
  })
}

export const closeProductInfo = () => dispatch => {
  dispatch({
    type: CLOSE_PRODUCT_INFO
  })
}

export const makeReturnToCart = () => dispatch => {
  dispatch({
    type: MAKE_REORDER
  })
}

export const addPromoPizza = (pizza) => (dispatch) => {
  dispatch({
    type: ADD_PROMO_PIZZA,
    payload: pizza
  })
}

export const recountTotalPrice = (discount = null, type) => async dispatch => {
  if (type) {
    dispatch({
      type: RECOUNT_TOTAL_PRICE,
      discount,
      payload: type
    })
    return;
  }
  dispatch({
    type: RECOUNT_TOTAL_PRICE,
    discount,
  })
}

export const returnInitialProducts = (type) => async dispatch => {
  await dispatch({
    type: RETURN_INITIAL_PRODUCTS,
    payload: type
  })
}

export const firstTimeAdd = () => async (dispatch) => {
  await dispatch({
    type: FIRST_TIME_ADD
  })
}

export const changeComment = (id, value) => {
  return {
    type: CHANGE_COMMENT,
    payload: {
      id,
      value
    }
  }
}