/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
const PizzaSauce = ({ finalPizza, sauce, chooseSauce }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (finalPizza.options && finalPizza.options.sauce) {
      if (finalPizza.options.sauce.product_option_id === sauce.id) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    } else {
      setChecked(false)
    }
  }, [finalPizza.options])

  return (
    <FormControlLabel
      checked={checked}
      onClick={(e) => chooseSauce(e, sauce.id, sauce.price)}
      value={sauce.option_value_description.name}
      key={sauce.id}
      control={<Radio color="primary" />}
      label={sauce.option_value_description.name} />
  )
}

export default PizzaSauce
