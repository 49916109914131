import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  comboOption: {
    width: '18%',
    height: '100px',
    margin: '1%',
    padding: '15px',
    cursor: 'pointer',
    transition: '0.3s'
  },
  activeOption: {
    border: `2px solid ${theme.palette.secondary.main}`,
    transform: 'scale(1.1)'
  }
})
const ComboOption = ({ chosenProducts, setChosenProducts, categoryId, classes, option, chosenItem, setChosenItem }) => {
  const changeActiveOption = () => {
    setChosenItem(option);
  }
  return (
    <Button onClick={changeActiveOption} variant='outlined' className={`${classes.comboOption} ${chosenItem.product_id === option.product_id ? classes.activeOption : ''}`}>
      {option.name}
    </Button>
  )
}

export default withStyles(styles)(ComboOption)
