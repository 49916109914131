/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHttp}                    from 'hooks/httpHook';
import axios             from 'axios';

import Dialog       from '@material-ui/core/Dialog';
import Typography   from '@material-ui/core/Typography';
import TextField     from "@material-ui/core/TextField";
import withStyles    from "@material-ui/core/styles/withStyles";
import Grid          from "@material-ui/core/Grid";
import Button        from "@material-ui/core/Button";
import Alert         from "@material-ui/lab/Alert";
import Snackbar      from "@material-ui/core/Snackbar";
import NativeSelect  from "@material-ui/core/NativeSelect";
import InputLabel    from "@material-ui/core/InputLabel";
import Select        from "@material-ui/core/Select";
import MenuItem      from "@material-ui/core/MenuItem";
import FormControl   from "@material-ui/core/FormControl";
import {ButtonGroup} from "@material-ui/core";
import ProductsWindow   from "./ProductsWindow";

const styles = theme => ({
  orderTitle   : {
    display       : 'flex',
    justifyContent: 'space-between',
    marginBottom  : theme.spacing(1)
  },
  orderContent : {
    width       : '100%',
    minHeight   : '200px',
    height      : '428px',
    background  : '#F3F3F3',
    borderRadius: '10px',
    padding     : theme.spacing(1),
    position    : 'relative'
  },
  orderWrap    : {
    padding  : theme.spacing(2),
    minHeight: '500px',
  },
  payContainer : {
    position     : 'absolute',
    bottom       : theme.spacing(1),
    right        : theme.spacing(1),
    display      : 'flex',
    flexDirection: 'column',
  },
  orderInfo    : {
    position: 'absolute',
    bottom  : theme.spacing(1),
    left    : theme.spacing(1),
    width   : '60%',

  },
  infoContainer: {
    width       : '100%',
    background  : '#E5DDF2',
    borderRadius: '10px',
    marginTop   : theme.spacing(1),
    padding     : theme.spacing(1),
    color       : '#6A6A6A'
  },
  confirmButton: {
    float: 'right',
    margin: '2rem 0.5rem 0.5rem 0',
  },
})

const EditorWindow = ({classes, id, open, setOpen, success, error, errorText}) => {
  const { request, loading } = useHttp();
  const [order, setOrder]       = useState({});
  const [stores, setStores] = useState([]);
  const [openProducts, setOpenProducts]     = useState(false);
  const [products, setProducts]     = useState([]);

  const handleClose = () => {
    setOpen(false)
  }

  const getOrderData = async () => {
    const data = await request(`/admin/orders/${id}`, 'get');
    if (data) {
      setOrder(data);
    }
  }

  const getStores = async () => {
    const data = await request(`/stores`, 'get');
    if (data) {
      setStores(data);
    }
  }

  useEffect(() => {
    if (id) {
      getOrderData();
      getStores();
    }
  }, [id])

  const handleChange = e => {
    setOrder({
      ...order,
      [e.target.name]: e.target.value
    })
  }

  const handleSave = async () => {
    await axios.put(`/admin/orders/${id}`, {order, products: products})
      .then(res => {
        success(true)
        error(false)
        let timer1 = setTimeout(() => {
          success(true)
          error(false)
          window.location.reload()}, 1000)

        return () => {
          clearTimeout(timer1)
        }

      })
      .catch(e => {
        error(true)
        success(false)
        let timer1 = setTimeout(() => {error(false); success(false)}, 6000)
        errorText(e.response.data)
        return () => {
          clearTimeout(timer1)
        }
      })
  }

  const handleOpenProducts = () => {
    setOpenProducts(true);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <ProductsWindow open={openProducts} setOpen={setOpenProducts} products={order.options} setProducts={setProducts} />
      {
        loading ? <p>загрузка...</p> : (
      <Grid className={classes.orderWrap}>
        <Typography variant={'h5'}>Заказ №{order.invoice_no}</Typography>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              id="first_name-helperText"
              label="Имя клиента"
              value={order.first_name || ''}
              name="first_name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="telephone-helperText"
              label="Телефон"
              value={order.telephone || ''}
              name="telephone"
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="coupon-helperText"
              label="Купон"
              value={order.coupon || ''}
              name="coupon"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              style={{width:'100%'}}
              id="shipping_address-helperText"
              label="Адрес доставки"
              value={order.shipping_address || ''}
              name="shipping_address"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InputLabel shrink htmlFor="shipping_method-native-label-placeholder">Метод доставки</InputLabel>
            <NativeSelect
              value={order.shipping_method}
              style={{width: '100%'}}
              name="shipping_method"
              onChange={handleChange}
            >
              <option value={'take_away'}>Самовывоз</option>
              <option value={'delivery'}>Доставка</option>
            </NativeSelect>
            {/*<TextField
              id="shipping_method-helperText"
              label="Метод доставки"
              value={order.shipping_method || ''}
              name="shipping_method"
              onChange={handleChange}
            />*/}
          </Grid>
          <Grid item xs={4}>
            <InputLabel shrink htmlFor="pay_method-native-label-placeholder">Способ оплаты</InputLabel>
            <NativeSelect
              value={order.pay_method}
              style={{width: '100%'}}
              name="pay_method"
              onChange={handleChange}
            >
              <option value={'cod'}>Наличными</option>
              <option value={'card'}>Карта</option>
            </NativeSelect>
          </Grid>
          <Grid item xs={4}>
            <InputLabel shrink htmlFor="platform_id-native-label-placeholder">Платформа</InputLabel>
            <NativeSelect
              value={order.platform_id}
              style={{width: '100%'}}
              name="platform_id"
              onChange={handleChange}
            >
              <option value={1}>CRM</option>
              <option value={2}>VK</option>
              <option value={3}>Delivery</option>
              <option value={4}>Yandex</option>
            </NativeSelect>
            {/*<TextField
              id="platform_id-helperText"
              label="Платформа"
              value={order.platform_id || ''}
              name="platform_id"
              onChange={handleChange}
            />*/}
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{width: '100%'}}
              id="comment-helperText"
              label="Комментарий"
              value={order.comment || ''}
              name="comment"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              id="total-helperText"
              label="Итого"
              value={order.total || ''}
              name="total"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
              <InputLabel shrink htmlFor="order_status_id-native-label-placeholder">Статус</InputLabel>
              <NativeSelect
                id="status"
                value={order.order_status_id}
                style={{width: '100%'}}
                name="order_status_id"
                onChange={handleChange}
              >
                <option value={1}>Ожидание</option>
                <option value={2}>Выполняется</option>
                <option value={3}>Доставлено</option>
                <option value={4}>Отменено</option>
                <option value={5}>Возврат</option>
                <option value={6}>Завершен</option>
                <option value={7}>Ко времени</option>
              </NativeSelect>
            {/*<TextField
              id="order_status_id-helperText"
              label="Статус заказа"
              value={order.order_status_id || ''}
              name="order_status_id"
              onChange={handleChange}
            />*/}
          </Grid>
          <Grid item xs={4}>
            <InputLabel shrink htmlFor="is_paid-native-label-placeholder">
              Оплачен
            </InputLabel>
            <NativeSelect
              value={order.is_paid}
              onChange={handleChange}
              style={{width: '100%'}}
              name="is_paid"
            >
              <option value={0}>Нет</option>
              <option value={1}>Да</option>
            </NativeSelect>
            {/*<TextField
              id="is_paid-helperText"
              label="Оплачен"
              value={order.is_paid || ''}
              name="is_paid"
              onChange={handleChange}
            />*/}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InputLabel shrink htmlFor="is_closed-native-label-placeholder">
              Закрыт
            </InputLabel>
            <NativeSelect
              value={order.is_closed}
              onChange={handleChange}
              style={{width: '100%'}}
              name="is_closed"
            >
              <option value={0}>Нет</option>
              <option value={1}>Да</option>
            </NativeSelect>
            {/*<TextField
              id="is_closed-helperText"
              label="Закрыт"
              value={order.is_closed || ''}
              name="is_closed"
              onChange={handleChange}
            />*/}
          </Grid>
          <Grid item xs={4}>
            <InputLabel shrink htmlFor="is_site-native-label-placeholder">
              С сайта
            </InputLabel>
            <NativeSelect
              value={order.is_site}
              onChange={handleChange}
              style={{width: '100%'}}
              name="is_site"
            >
              <option value={0}>Нет</option>
              <option value={1}>Да</option>
            </NativeSelect>
            {/*<TextField
              id="is_site-helperText"
              label="С сайта"
              value={order.is_site || ''}
              name="is_site"
              onChange={handleChange}
            />*/}
          </Grid>
          <Grid item>
            <TextField
              id="floor-helperText"
              label="Этаж"
              value={order.floor || ''}
              name="floor"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              id="porch-helperText"
              label="Подъезд"
              value={order.porch || ''}
              name="porch"
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="porch_code-helperText"
              label="Домофон"
              value={order.porch_code || ''}
              name="porch_code"
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="flat-helperText"
              label="Квартира"
              value={order.flat || ''}
              name="flat"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              id="cash-helperText"
              label="Сдача"
              value={order.cash || ''}
              name="cash"
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="time-helperText"
              label="Ко времени"
              value={order.time || ''}
              name="time"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel shrink htmlFor="is_site-native-label-placeholder">
              Магазин
            </InputLabel>
            <NativeSelect
              value={order.store_id}
              onChange={handleChange}
              style={{width: '100%'}}
              name="store_id"
            >
              {stores.map((option, i) => {
                return <option key={i} value={option.id}>{option.name}</option>
              })}
            </NativeSelect>
            {/*<TextField
              id="store_id-helperText"
              label="Магазин"
              value={order.store_id}
              name="store_id"
              onChange={handleChange}
            />*/}
          </Grid>
        </Grid>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <ButtonGroup color="primary" aria-label="outlined primary button group" style={{margin: '10px 0'}}>
            <Button variant="outlined" onClick={handleClose}>Закрыть</Button>
            <Button color="secondary" variant="contained" onClick={handleOpenProducts}>Товары</Button>
            <Button color="primary" variant="contained" onClick={handleSave}>Сохранить</Button>
          </ButtonGroup>
        </div>
      </Grid>
        )
      }
    </Dialog>
  )
}

export default withStyles(styles)(EditorWindow)