/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import CashOrderItem from '../CashOrderItem';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTime } from 'hooks/timeHook';
import { ReactComponent as DeliveryIcon } from 'img/icons/delivery.svg';
import { ReactComponent as YandexIcon } from 'img/icons/yandex.svg';
import { ReactComponent as VkIcon } from 'img/icons/vk.svg';
import OrderDetailsIcon from './OrderDetails';
import DeliveryDetailsIcon from './DeliveryDetails'
//MUI 
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//icons 
import AddIcon from '@material-ui/icons/Add';
import { pink } from '@material-ui/core/colors';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
const styles = theme => ({
  ...theme.mainStyles,
  gridOrderInfo: {
    gridArea: '1 / 8 / 3 / 11',
    position: 'relative',
  },
  finalPrice: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px'
  },
  orderInfoContent: {
    height: '400px',
    overflow: 'auto'
  },
  orderInfoTitle: {
    position: 'relative',
    padding: '4px 1rem',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.2)'
  },
  totalPriceAndHint: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  orderStart: {
    marginTop: '15px'
  },
  hintBold: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  mainTitle: {
    fontSize: '1.4rem',
  },
  orderNumber: {
    padding: '10px 20px',
    color: 'white',
    fontSize: '1.5rem',
    background: pink[700],
  },
  orderButtons: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  orderButton: {
    fontSize: '35px',
    width: '60px',
    height: '60px',
    margin: '5px',
    borderRadius: '15px'
  },
  orderIcon: {
    fontSize: '35px'
  },
  serviceButton: {
    width: '40px',
    margin: '5px'
  },
  orderDetails: {
    display: 'flex',
    alignItems: 'start'
  }
})

const СashOrderInfo = ({ startServiceOrder, startDeliveryOrder, startOrder, order: { invoice_no, flat, porch, porch_code, floor, shipping_address, shipping_method, order_status_id, created_at, id, products, total, is_site }, order, setOrder, classes }) => {
  const { formatDate, dateFromNow, deliveryTime } = useTime();
  const [formatedDate, setFormatedDate] = useState(`${dateFromNow(created_at)} (${formatDate(created_at, 'HH:mm')})`);

  useEffect(() => {
    setFormatedDate(`${dateFromNow(created_at)} (${formatDate(created_at, 'HH:mm')})`);
    let timer = null;
    if (id) {
      timer = setInterval(() => {
        setFormatedDate(`${dateFromNow(created_at)} (${formatDate(created_at, 'HH:mm')})`);
      }, 60000)
    }
    return () => {
      clearInterval(timer);
    }
  }, [id])

  const startUsualOrder = async () => {
    await startOrder();
  }

  const startTogoOrder = async () => {
    await startOrder();
    startDeliveryOrder();
  }

  const makeServiceOrder = async (id) => {
    await startServiceOrder(id)
    await startOrder(id)
  }



  const startMakingORder = () => {
    axios.put(`/orders/${id}`, { order_status_id: 2 })
      .then(() => setOrder({}))
      .catch(err => console.error(err.response.data));
  }


  return (
    <Card className={classes.gridOrderInfo}>
      <Grid className={classes.orderButtons}>
        <Grid>
          <Link to="/cash/menu" onClick={() => makeServiceOrder(4)}>
            <YandexIcon className={classes.serviceButton} />
          </Link>
          <Link to="/cash/menu" onClick={() => makeServiceOrder(3)}>
            <DeliveryIcon className={classes.serviceButton} />
          </Link>
          <Link to="/cash/menu" onClick={() => makeServiceOrder(2)}>
            <VkIcon className={classes.serviceButton} />
          </Link>
        </Grid>
        <Grid>
          <Button variant="outlined" component={Link} to='/cash/menu' onClick={startTogoOrder} color="primary" aria-label="Новый заказ" className={classes.orderButton} >
            <LocalShippingIcon className={classes.orderIcon} />
          </Button>
          <Button variant="outlined" component={Link} to='/cash/menu' onClick={startUsualOrder} color="primary" aria-label="Новый заказ" className={classes.orderButton}>
            <AddIcon className={classes.orderIcon} />
          </Button>
        </Grid>
      </Grid>
      {
        order.id ? (
          <Grid className={classes.orderNumber}>
            Заказ {invoice_no} - {is_site === 1 ? 'Сайт' : is_site === 0 ? 'CRM' : ''}
          </Grid>
        ) : null
      }
      <Grid className={classes.orderInfoTitle}>
        <Typography className={classes.mainTitle}>
          {
            id ? `Создан ${formatedDate}` : 'Заказ не выбран'
          }
        </Typography>
      </Grid>
      <CardContent>
        <Grid className={classes.orderInfoContent}>
          {
            products ? products.map(item => <CashOrderItem item={item} key={item.id} />) : ''
          }
        </Grid>
        <Divider />
        {
          products ? (
            <Grid className={classes.totalPriceAndHint}>
              <Grid className={classes.flexbox}>
                <Button color="primary" variant="outlined">
                  Итого: {total} руб.
              </Button>
                <DeliveryDetailsIcon order={order} />
                <OrderDetailsIcon order={order} deliveryTime={deliveryTime} /> 
              </Grid>
              <Grid className={classes.finalPrice}>

              </Grid>
              {
                order_status_id === 1 ? (
                  <Button className={classes.orderStart} color="secondary" onClick={startMakingORder} variant="contained">
                    Начать выполнение заказа
                  </Button>
                ) : null
              }
            </Grid>

          ) : null
        }
      </CardContent>
    </Card >
  )
}

export default withStyles(styles)(СashOrderInfo)
