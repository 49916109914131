import React from 'react';
import { connect } from 'react-redux';
import CategoryItem from '../CategoryItem';
import PropTypes from 'prop-types';
//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
const styles = (theme) => ({
  category: {
    paddingBottom: '2px'
  },
  categoryWrap: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridGap: theme.spacing(1),
  },
  categoryTitle: {
    fontSize: '30px',
    padding: '5px 0 5px 5px',
    color: `${theme.palette.primary.dark}`
  },
})
const Category = ({ category, classes }) => {
  const products = category.products;
  const productsArr = products.length > 0 ? products.map(product => <CategoryItem key={product.id} product={product} />) : <Typography vatiant="h5">Позиций пока нет</Typography>;

  return (
    <div className={classes.category}>
      <Typography variant="h1" className={classes.categoryTitle}>
        {category.name}
      </Typography>
      <Grid className={classes.categoryWrap}>
        {
          productsArr
        }
      </Grid>
    </div>
  )
}
Category.propTypes = {
  data: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  data: state.data
})
export default connect(mapStateToProps)(withStyles(styles)(Category));
